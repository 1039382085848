//messages.js
export default {
	sendEmail:"Envoyer un email",
	enterYourEmailToSendYouAResetLink:"Entrez votre email pour vous envoyer un lien de réinitialisation",
	salesReport: "Rapport des ventes",
	totalRevenue: "Revenu total",
	revenueToday: "Revenu aujourd'hui",
	totalOrders: "Total des commandes",
	newOrdersToday: "Nouvelles commandes aujourd'hui",
	userStats: "Statistiques utilisateur",
	salesByCategory: "Ventes par catégorie",
	trending: "Tendances",
	newArticle: "Nouvel article",
	today: "Aujourd'hui",
	advertiseCampaign: "Campagne de publicité",
	bestSeller: "Best-seller",
	sales: "Ventes",
	rating: "Évaluation",
	comments: "commentaires",
	addNewBlogItem: "Ajouter un nouvel élément de blog",
	addNew: "Ajouter un nouveau",
	globalSalesByTopLocation: "Ventes mondiales par emplacement de choix",
	salesTarget: "Objectif de ventes",
	orders: "Ordres",
	newVisitors: "Nouveaux visiteurs",
	subscriptions: "Abonnements",
	blogTitle: "Titre du Blog",
	description: "La description",
	postBlog: "Publier un blog",
	scheduleLater: "Horaire plus tard",
	recentPosts: "Messages récents",
	browserStats: "Statistiques du navigateur",
	send: "Envoyer",
	citiesAndClients: "Villes et clients",
	connections: "Les liaisons",
	couponStats: "Statistiques sur les coupons",
	dailySales: "Ventes quotidiennes",
	salesToday: "Ventes aujourd'hui",
	expenses: "Les dépenses",
	totalExpense: "Total des dépenses",
	inbox: "Boîte de réception",
	compose: "Composer",
	all: "Tout",
	unread: "Non lu",
	overallTrafficStatus: "Etat général du trafic",
	whoToFollow: "Qui suivre",
	follow: "Suivre",
	following: "Suivant",
	photos: "Photos",
	realTimeTraffic: "Trafic en temps réel",
	recentComments: "Commentaires récents",
	recentOrderAndStatus: "Commande récente et statut",
	totalSales: "Ventes totales",
	newCustomers: "nouveaux clients",
	totalCustomers: "Total clients",
	skills: "Compétences",
	likes: "Aime",
	followers: "Suiveurs",
	statistic: "Statistique",
	sold: "Vendu",
	inStock: "En stock",
	amountOfStocksSold: "Quantité de stocks vendus",
	viewMore: "Afficher plus",
	systemReports: "Rapports système",
	view: "Vue",
	edit: "modifier",
	delete: "Effacer",
	toDoList: "Liste de choses à faire",
	topBuyers: "Top acheteurs",
	viewProfile: "Voir le profil",
	sendMessage: "Envoyer le message",
	articles: "Des articles",
	buyersStats: "Statistiques des acheteurs",
	salesAndVisitorStatus: "Statut de vente et de visiteur",
	websiteStatistics: "Statistiques du site",
	serverLoad: "Charge du serveur",
	CPULoad: "Charge du processeur",
	storageRemain: "Le stockage reste",
	bandwidthUse: "Utilisation de la bande passante",
	recentTestimonial: "Témoignage récent",
	productStats: "Statistiques du produit",
	activityTimeline: "Calendrier d'activité",
	themeSettings: "Réglage des thèmes",
	chooseThemeColor: "Choisir la couleur du thème",
	boxLayout: "Disposition de la boîte",
	darkMode: "Mode sombre",
	quickLinks: "Liens rapides",
	notifications: "Les notifications",
	system: "Système",
	quickResults: "Résultats rapides",
	account: "Compte",
	autoUpdates: "Mises à jour automatiques",
	offlineMode: "Mode hors-ligne",
	locationShare: "Partager l'emplacement",
	showOfflineUser: "Afficher l'utilisateur hors ligne",
	saveHistory: "Sauvegarder l'historique",
	todaySales: "Ventes aujourd'hui",
	itemsSold: "objets vendus",
	customerTarget: "Client cible",
	ofTheMonth: "du mois",
	myProfile: "Mon profil",
	calendar: "Calendrier",
	myInbox: "Ma boîte de réception",
	myTasks: "Mes tâches",
	logOut: "Connectez - Out",
	lineChart: "Graphique en ligne",
	barChart: "Diagramme à bandes",
	doughnutChart: "Graphique en beignet",
	pieChart: "Camembert",
	radarChart: "Carte radar",
	polarAreaChart: "Carte de la zone polaire",
	bubbleChart: "Diagramme à bulles",
	funnelChart: "Tableau d'entonnoir",
	vue2Dragula: "Vue 2 Dragula",
	vueDraggable: "Vue Draggable",
	draggableResizable: "Draggable Resizable",
	quillEditor: "Quill Editor",
	wysiwyg: "WYSIWYG",
	cropImage: "Crop Image",
	previewImage: "Image d'aperçu",
	baseConfig: "Base de configuration",
	audioTrackAndPlaysInline: "Piste audio et joue en ligne",
	hlsLive: "HLS Live",
	listOfAllMaterialIcons: "Liste de toutes les icônes matérielles",
	arrowsAndDirectionIcons: "Flèches et icônes de direction",
	webAppIcons: "Icônes d'application Web",
	controlIcons: "Icônes de contrôle",
	textEditorIcons: "Icônes de l'éditeur de texte",
	layoutIcons: "Icônes de mise en page",
	brandIcons: "Icônes de marque",
	googleMap: "Google Map",
	leafletMap: "Carte du dépliant",
	choosePlan: "Choisissez un plan",
	login: "S'identifier",
	enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Entrez le nom d'utilisateur et le mot de passe pour accéder au panneau de configuration d'Adminize.",
	rememberMe: "Souviens-toi de moi",
	forgotPassword: "Mot de passe oublié?",
	dontHaveAnAccount: "Vous n'avez pas de compte?",
	orLoginWith: "Ou Connectez-vous avec",
	registerToAdmin: "S'inscrire à l'administrateur",
	createAccount: "Créer un compte",
	alreadyHaveAnAccount: "Vous avez déjà un compte ?",
	orRegisterWith: "Ou s'inscrire avec",
	basicTables: "Tableaux de base",
	tableInverse: "Tableau Inverse",
	tableHead: "Tête de table",
	stripedRows: "Lignes rayées",
	borderedTable: "Table Bordée",
	hoverableTable: "Table Hoverable",
	contextualTable: "Table contextuelle",
	responsiveTable: "Tableau sensible",
	responsiveTable2: "Tableau sensible 2",
	collapse: "Effondrer",
	toggleCollapse: "Basculer Réduire",
	toggleInnerCollapse: "Basculer l'effondrement interne",
	multipleCollapse: "Effondrement multiple",
	toggleBothCollapseAandB: "Basculer les deux effondrement A et B",
	accordion: "Accordéon",
	basicTab: "Onglet de base",
	closedItem: "Article fermé",
	closedItem2: "Point fermé 2",
	linkColor: "Couleur du lien",
	additionalcontentInsideAlerts: "Contenu supplémentaire dans les alertes",
	normalAlertsWithDismiss: "Alertes normales avec rejet",
	autoDismissingAlerts: "Alertes d'abandon automatique",
	showAlertWithCountdownTimer: "Afficher l'alerte avec compte à rebours",
	badgeSize: "Taille du badge",
	badgesColor: "Couleur des badges",
	predefinedButtonStyles: "Styles de boutons prédéfinis",
	outlineButtons: "Boutons de contour",
	buttonsSizes: "Taille des boutons",
	blockLevelButtons: "Boutons de niveau de bloc",
	blockLevelButton: "Bouton de niveau de blocage",
	dropDownAndDropUp: "DropDown & Dropup",
	carousel: "Carrousel",
	firstSlide: "Première diapositive",
	helloWorld: "Bonjour le monde",
	basicUsage: "Utilisation de base",
	alignment: "Alignement",
	simpleProgressBarWithLables: "Barre de progression simple avec étiquettes",
	coloredProgressBars: "Barres de progression colorées",
	stackedBars: "Bars empilés",
	sizingProressBar: "Barre de progression de dimensionnement",
	stripedProgressBar: "Barre de progression rayée",
	pillsVariant: "Variante de pilules",
	advancedExamples: "Exemples avancés",
	previous: "précédent",
	next: "Prochain",
	dynamicTabs: "Onglets dynamiques",
	imaToolTip: "Je suis une infobulle!",
	hoverMe: "Survolez moi",
	positioning: "Positionnement",
	tooltipOnTop: "Info-bulle sur le dessus",
	tooltipOnBottom: "Info-bulle sur le bas",
	tooltipOnLeft: "Info-bulle à gauche",
	tooltipOnRight: "Info-bulle à droite",
	popover: "Popover",
	popoverTitle: "Titre Popover",
	popoverPositioning: "Popover Positionnement",
	popoverOnTop: "Popover sur le dessus",
	popoverOnBottom: "Popover sur le fond",
	popoverOnLeft: "Popover à gauche",
	popoverOnRight: "Popover à droite",
	by: "Par",
	editPost: "Modifier le message",
	deletePost: "Supprimer le message",
	standard: "la norme",
	forMostOfTheUsers: "Pour la plupart des utilisateurs",
	mega: "Méga",
	master: "Maîtriser",
	forDeveloper: "Pour développeur",
	forLargeEnterprises: "Pour les grandes entreprises",
	dashboard: "Tableau de bord",
	dashboard1: "Tableau de bord 1",
	dashboard2: "Tableau de bord 2",
	uiElements: "Éléments d'interface utilisateur",
	alerts: "Les alertes",
	buttons: "Boutons",
	progressBar: "Barre de progression",
	tabs: "Onglets",
	accordions: "Les accordéons",
	pagination: "Pagination",
	tooltip: "Info-bulle",
	badges: "Insignes",
	cards: "Cartes",
	editor: "Éditeur",
	dragAndDrop: "Glisser déposer",
	icons: "Les icônes",
	charts: "Graphiques",
	tables: "les tables",
	maps: "Plans",
	pages: "Des pages",
	users: "Utilisateurs",
	session: "Session",
	features: "Caractéristiques",
	themify: "Themify",
	material: "Matériel",
	fontAwesome: "Police génial",
	vueChartjs: "Vue Chartjs",
	vueEcharts: "Vue Echarts",
	basic: "De base",
	responsive: "Sensible",
	googleMaps: "Google Maps",
	leafletMaps: "Cartes de dépliants",
	gallery: "Galerie",
	pricing: "Prix",
	blank: "Blanc",
	userProfile: "Profil de l'utilisateur",
	usersList: "Liste des utilisateurs",
	signUp: "S'inscrire",
	lockScreen: "Écran verrouillé",
	imageCropper: "Cropper Image",
	videoPlayer: "Lecteur vidéo",
	dropzone: "Zone de largage",
	reply: "Répondre",
	dashboardDashboard1: "Tableau de bord / Tableau de bord 1",
	dashboardDashboard2: "Tableau de bord / Tableau de bord 2",
	uiElementsButtons: "Éléments d'interface utilisateur / boutons",
	uiElementsCalendar: "Éléments d'interface utilisateur / Calendrier",
	uiElementsProgressBar: "Eléments de l'interface utilisateur / Barre de progression",
	uiElementsTabs: "Eléments d'interface utilisateur / Onglets",
	uiElementsAccordions: "UI-Elements / Accordions",
	uiElementsPagination: "UI-Elements / Pagination",
	uiElementsTooltip: "Éléments d'interface utilisateur / Tooltip",
	uiElementsBadges: "Eléments d'interface utilisateur / Badges",
	uiElementsCards: "Eléments d'interface utilisateur / Cartes",
	uiElementsAlerts: "Eléments d'interface utilisateur / Alertes",
	uiElementsCarousel: "Eléments d'interface utilisateur / carrousel",
	editorQuillEditor: "Editeur / Editeur de plumes",
	editorWYSIWYG: "Éditeur / WYSIWYG",
	dragAndDropVueDraggable: "Drag And Drop / Vue Draggable",
	dragAndDropVue2Dragula: "Drag And Drop / Vue2 Dragula",
	dragAndDropDraggableResizable: "Drag And Drop / Draggable Resizable",
	iconsThemify: "Icônes / Themify",
	iconsMaterial: "Icônes / Matériel",
	iconsFontAwesome: "Icônes / Police Awesome",
	chartsVueChartjs: "Graphiques / Vue Chartjs",
	chartsVueEcharts: "Graphiques / Echarts de vue",
	mapsGoogleMaps: "Cartes / Google Maps",
	mapsLeafletMaps: "Cartes / Dépliant Cartes",
	tablesTables: "Tables / Tables",
	tablesResponsiveTable: "Tables / Table sensible",
	pagesGallery: "Pages / Galerie",
	pagesPricing: "Pages / Prix",
	pagesBlank: "Pages / Blank",
	usersUserProfile: "Utilisateurs / Profil d'utilisateur",
	usersUsersList: "Utilisateurs / Liste d'utilisateurs",
	featuresImageCropper: "Caractéristiques / Cropper Image",
	featuresVideoPlayer: "Caractéristiques / Lecteur vidéo",
	featuresDropzone: "Caractéristiques / Dropzone",
	crm:"CRM",
	reports:"Rapports",
	dashboardCRM: "Dashboard / CRM",
	dashboardReports: "Tableau de bord / Rapports",
	invoices:"Factures",
	payments:"Paiements",
	taxRates:"Les taux d'imposition",
	addTickets:"Ajouter des billets",
	visitors:"Visiteuses",
	revenue:"Revenu",
	deals:"Offres",
	trade:"Commerce",
	projectStatus:"L'état du projet",
	upcomingEvents:"évènements à venir",
	ongoingProjects:"Projets en cours",
	supervisor:"Superviseuse",
	duration:"Durée",
	netWorth:"NetWorth",
	email:"Email",
	phone:"Téléphone",
	viewAllNotifications:"Afficher toutes les notifications",
	chat:"Bavarder",
	transactionList:"Liste de transaction",
	transferReport:"Rapport de transfert",
	expenseCategory:"Catégorie de dépenses",
	projects: "Projets",	
	projectDetails: "détails du projet",
	clients: "Les clients",
	search: "Chercher",
	add: "Ajouter",
	crmProjects: "CRM / Projets",
	projectGrid: "Grille de projet",
	projectList: "Liste de projets",
	crmProjectDetails: "CRM / Détails du projet",
	filesUploaded: "Fichiers téléchargés",
	statistics: "Statistiques",
	projectGallery: "Galerie de projet",
	progress: "Le progrès",
	allClients: "Tous les clients",
	favourite: "Préféré",
	recentlyAdded: "Récemment ajouté",
	crmReports: "CRM / Rapports",
	crmClients: "CRM / clients",
	dashboardCrypto: "Tableau de bord / Crypto",
	dashboardCrm: "Tableau de bord / CRM",
	crypto: "Crypto",
	marketCap: "Capitalisation boursière",
	wallet: "Portefeuille",
	trade: "Commerce",
	tradeHistory: "Histoire du commerce",
	safeTrade: "Commerce sécurisé",
	exchangeStatistics: "Statistiques d'échange",
	quickTrade: "Commerce rapide",
	recentTrades: "Métiers récents",
	exchangeRate: "Taux de change",
	coinsList: "Liste des pièces",
	cryptoMarketCap: "Crypto / Capitalisation boursière",
	cryptoWallet: "Crypto / Portefeuille",
	cryptoTrade: "Crypto / Commerce",
	walletAddress: "Adresse du portefeuille",
	buyCryptocurrency: "Acheter Crypto Currency",
	expenditureStats: "Statistiques de dépenses",
	sellCryptocurrency: "Vendre de la monnaie crypto",
	transferCryptocurrency: "Transfert de devise cryptographique",
	bankDetails: "Coordonnées bancaires"
}
//messages.js
export default {
   sendEmail:"メールを送る",
   enterYourEmailToSendYouAResetLink:"リンクをリセットするためのメールアドレスを入力してください",
   salesReport: "セールスレポート",
   totalRevenue: "総収入",
   revenueToday: "収入今日",
   totalOrders: "合計注文",
   newOrdersToday: "今日の新しいご注文",
   userStats: "ユーザー統計",
   salesByCategory: "カテゴリー別売上高",
   trending: "トレンド",
   newArticle: "新しい記事",
   today: "今日",
   advertiseCampaign: "広告キャンペーン",
   bestSeller: "ベスト・セラー",
   sales: "販売",
   rating: "評価",
   comments: "コメント",
   addNewBlogItem: "新しいブログアイテムを追加",
   addNew: "新しく追加する",
   globalSalesByTopLocation: "トップロケーション別世界売上高",
   salesTarget: "販売目標",
   orders: "注文",
   newVisitors: "新しい訪問者",
   subscriptions: "定期購読",
   blogTitle: "ブログのタイトル",
   description: "説明",
   postBlog: "投稿ブログ",
   scheduleLater: "後でスケジュールを設定する",
   recentPosts: "最近の投稿",
   browserStats: "ブラウザの統計",
   send: "送信",
   citiesAndClients: "都市とクライアント",
   connections: "接続",
   couponStats: "クーポンの統計情報",
   dailySales: "毎日の販売",
   salesToday: "今日のセールス",
   expenses: "経費",
   totalExpense: "総経費",
   inbox: "受信トレイ",
   compose: "作成する",
   all: "すべて",
   unread: "未読",
   overallTrafficStatus: "全体のトラフィック状況",
   whoToFollow: "従う人",
   follow: "フォローする",
   following: "以下",
   photos: "写真",
   realTimeTraffic: "リアルタイムトラフィック",
   recentComments: "最近のコメント",
   recentOrderAndStatus: "最近の注文とステータス",
   totalSales: "トータルセールス",
   newCustomers: "新しいお客様",
   totalCustomers: "顧客総数",
   skills: "技能",
   likes: "好き",
   followers: "フォロワー",
   statistic: "統計",
   sold: "売れた",
   inStock: "在庫あり",
   amountOfStocksSold: "売却株式数",
   viewMore: "もっと見る",
   systemReports: "システムレポート",
   view: "ビュー",
   edit: "編集",
   delete: "削除",
   toDoList: "To-Doリスト",
   topBuyers: "トップバイヤー",
   viewProfile: "プロフィールを見る",
   sendMessage: "メッセージを送る",
   articles: "記事",
   buyersStats: "バイヤーの統計",
   salesAndVisitorStatus: "販売と訪問者のステータス",
   websiteStatistics: "ウェブサイトの統計",
   serverLoad: "サーバー負荷",
   CPULoad: "CPU負荷",
   storageRemain: "ストレージは残っています",
   bandwidthUse: "帯域幅の使用",
   recentTestimonial: "最近の功績",
   productStats: "製品の統計",
   activityTimeline: "活動のタイムライン",
   themeSettings: "テーマ設定",
   chooseThemeColor: "テーマカラーを選択",
   boxLayout: "ボックスレイアウト",
   darkMode: "ダークモード",
   quickLinks: "クイックリンク",
   notifications: "通知",
   system: "システム",
   quickResults: "速い結果",
   account: "アカウント",
   autoUpdates: "自動更新",
   offlineMode: "オフラインモード",
   locationShare: "ロケーション共有",
   showOfflineUser: "オフラインユーザーを表示する",
   saveHistory: "履歴を保存",
   todaySales: "今日の販売",
   itemsSold: "売れた商品",
   customerTarget: "顧客目標",
   ofTheMonth: "今月の",
   myProfile: "私のプロフィール",
   calendar: "カレンダー",
   myInbox: "私の受信トレイ",
   myTasks: "私の仕事",
   logOut: "ログアウト",
   lineChart: "折れ線グラフ",
   barChart: "棒グラフ",
   doughnutChart: "ドーナツチャート",
   pieChart: "円グラフ",
   radarChart: "レーダーチャート",
   polarAreaChart: "ポーラーエリアチャート",
   bubbleChart: "バブルチャート",
   funnelChart: "ファンネルチャート",
   vue2Dragula: "Vue 2 Dragula",
   vueDraggable: "Vue Draggable",
   draggableResizable: "ドラッグ可能なサイズ変更可能",
   quillEditor: "クイルエディタ",
   wysiwyg: "WYSIWYG",
   cropImage: "クロップ画像",
   previewImage: "プレビュー画像",
   baseConfig: "基本構成",
   audioTrackAndPlaysInline: "オーディオトラックとインライン再生",
   hlsLive: "HLSライブ",
   listOfAllMaterialIcons: "すべてのマテリアルアイコンのリスト",
   arrowsAndDirectionIcons: "矢印と方向のアイコン",
   webAppIcons: "Webアプリケーションのアイコン",
   controlIcons: "コントロールアイコン",
   textEditorIcons: "テキストエディタのアイコン",
   layoutIcons: "レイアウトアイコン",
   brandIcons: "ブランドアイコン",
   googleMap: "グーグルマップ",
   leafletMap: "リーフレットマップ",
   choosePlan: "プランを選択",
   login: "ログイン",
   enterUsernameAndPasswordToAccessControlPanelOfAdminify: "管理者のコントロールパネルにアクセスするためのユーザー名とパスワードを入力します。",
   rememberMe: "私を覚えてますか",
   forgotPassword: "パスワードをお忘れですか？",
   dontHaveAnAccount: "アカウントを持っていないのですか？",
   orLoginWith: "またはでログインする",
   registerToAdmin: "管理者に登録",
   createAccount: "アカウントを作成する",
   alreadyHaveAnAccount: "すでにアカウントをお持ちですか ？",
   orRegisterWith: "またはで登録する",
   basicTables: "基本テーブル",
   tableInverse: "テーブル逆",
   tableHead: "テーブルヘッド",
   stripedRows: "ストライプ行",
   borderedTable: "ボーダードテーブル",
   hoverableTable: "ホバリングテーブル",
   contextualTable: "文脈表",
   responsiveTable: "レスポンシブテーブル",
   responsiveTable2: "レスポンス表2",
   collapse: "崩壊",
   toggleCollapse: "トグル折りたたみ",
   toggleInnerCollapse: "内部折りたたみを切り替える",
   multipleCollapse: "複数折りたたみ",
   toggleBothCollapseAandB: "折りたたみAとBの両方を切り替える",
   accordion: "アコーディオン",
   basicTab: "基本タブ",
   closedItem: "クローズドアイテム",
   closedItem2: "クローズドアイテム2",
   linkColor: "リンクの色",
   additionalcontentInsideAlerts: "アラート内の追加コンテンツ",
   normalAlertsWithDismiss: "通常のアラートは閉じます",
   autoDismissingAlerts: "警告を自動的に解除する",
   showAlertWithCountdownTimer: "カウントダウンタイマーで警告を表示する",
   badgeSize: "バッジサイズ",
   badgesColor: "バッジカラー",
   predefinedButtonStyles: "定義済みのボタンスタイル",
   outlineButtons: "アウトラインボタン",
   buttonsSizes: "ボタンのサイズ",
   blockLevelButtons: "ブロックレベルボタン",
   blockLevelButton: "ブロックレベルボタン",
   dropDownAndDropUp: "ドロップダウン＆ドロップダウン",
   carousel: "カルーセル",
   firstSlide: "最初のスライド",
   helloWorld: "こんにちは世界",
   basicUsage: "基本的な使用法",
   alignment: "アラインメント",
   simpleProgressBarWithLables: "Label付きのシンプルなプログレスバー",
   coloredProgressBars: "色付きプログレスバー",
   stackedBars: "積み重ね棒",
   sizingProressBar: "サイジングプログレスバー",
   stripedProgressBar: "ストライププログレスバー",
   pillsVariant: "ピルズバリアント",
   advancedExamples: "高度な例",
   previous: "前",
   next: "次",
   dynamicTabs: "動的タブ",
   imaToolTip: "私はツールチップです！",
   hoverMe: "ホバーミー",
   positioning: "ポジショニング",
   tooltipOnTop: "ツールチップ上に",
   tooltipOnBottom: "ツールチップのボトム",
   tooltipOnLeft: "左側のツールチップ",
   tooltipOnRight: "右のツールチップ",
   popover: "ポップオーバー",
   popoverTitle: "ポップオーバータイトル",
   popoverPositioning: "ポップオーバーポジショニング",
   popoverOnTop: "上のPopover",
   popoverOnBottom: "Popover On Bottom",
   popoverOnLeft: "左ポップオーバー",
   popoverOnRight: "右にポップオーバー",
   by: "〜によって",
   editPost: "投稿の編集",
   deletePost: "投稿の削除",
   standard: "標準",
   forMostOfTheUsers: "ほとんどのユーザーにとって",
   mega: "メガ",
   master: "マスター",
   forDeveloper: "開発者向け",
   forLargeEnterprises: "大企業向け",
   dashboard: "ダッシュボード",
   dashboard1: "ダッシュボード1",
   dashboard2: "ダッシュボード2",
   uiElements: "UI要素",
   alerts: "アラート",
   buttons: "ボタン",
   progressBar: "プログレスバー",
   tabs: "タブ",
   accordions: "アコーディオン",
   pagination: "ページネーション",
   tooltip: "ツールチップ",
   badges: "バッジ",
   cards: "カード",
   editor: "編集者",
   dragAndDrop: "ドラッグアンドドロップ",
   icons: "アイコン",
   charts: "チャート",
   tables: "テーブル",
   maps: "地図",
   pages: "ページ",
   users: "ユーザー",
   session: "セッション",
   features: "特徴",
   themify: "テーマ",
   material: "材料",
   fontAwesome: "フォント驚くばかり",
   vueChartjs: "Vue Chartjs",
   vueEcharts: "Vue Echarts",
   basic: "ベーシック",
   responsive: "応答性のある",
   googleMaps: "グーグルマップ",
   leafletMaps: "リーフレットマップ",
   gallery: "ギャラリー",
   pricing: "価格設定",
   blank: "ブランク",
   userProfile: "ユーザープロファイル",
   usersList: "ユーザーリスト",
   signUp: "サインアップ",
   lockScreen: "ロック画面",
   imageCropper: "Image Cropper",
   videoPlayer: "ビデオプレーヤー",
   dropzone: "Dropzone",
   reply: "応答",
   dashboardDashboard1: "ダッシュボード/ダッシュボード1",
   dashboardDashboard2: "ダッシュボード/ダッシュボード2",
   uiElementsButtons: "UI要素/ボタン",
   uiElementsCalendar: "UI要素/カレンダー",
   uiElementsProgressBar: "UI要素/進捗バー",
   uiElementsTabs: "UI要素/タブ",
   uiElementsAccordions: "UI要素/アコーディオン",
   uiElementsPagination: "UI要素/ページネーション",
   uiElementsTooltip: "UI要素/ツールチップ",
   uiElementsBadges: "UI要素/バッジ",
   uiElementsCards: "UI要素/カード",
   uiElementsAlerts: "UI要素/警告",
   uiElementsCarousel: "UI要素/カルーセル",
   editorQuillEditor: "エディタ/クイルエディタ",
   editorWYSIWYG: "エディタ/ WYSIWYG",
   dragAndDropVueDraggable: "ドラッグアンドドロップ/ビューのドラッグ可能",
   dragAndDropVue2Dragula: "ドラッグ＆ドロップ/ビュー2ドラッグ",
   dragAndDropDraggableResizable: "ドラッグアンドドロップ/サイズ変更可能なドラッグ可能",
   iconsThemify: "アイコン/テーマ",
   iconsMaterial: "アイコン/マテリアル",
   iconsFontAwesome: "アイコン/フォントAwesome",
   chartsVueChartjs: "Charts / Vue Chartjs",
   chartsVueEcharts: "チャート/ビューEチャート",
   mapsGoogleMaps: "地図/ Googleマップ",
   mapsLeafletMaps: "地図/リーフレットマップ",
   tablesTables: "テーブル/テーブル",
   tablesResponsiveTable: "テーブル/レスポンシブテーブル",
   pagesGallery: "ページ/ギャラリー",
   pagesPricing: "ページ/価格",
   pagesBlank: "ページ/ブランク",
   usersUserProfile: "ユーザー/ユーザープロファイル",
   usersUsersList: "ユーザー/ユーザー一覧",
   featuresImageCropper: "特徴/イメージ切り子",
   featuresVideoPlayer: "特長/ビデオプレーヤー",
   featuresDropzone: "機能/ドロップゾーン",
   crm:"CRM",
   reports:"レポート",
   dashboardCrm: "ダッシュボード/ CRM",
   dashboardReports: "ダッシュボード / レポート",
   invoices:"請求書",
   payments:"支払い",
   taxRates:"税率",
   addTickets:"チケットを追加",
   visitors:"訪問者",
   revenue:"収入",
   deals:"お得な情報",
   trade:"トレード",
   projectStatus:"プロジェクト状況",
   upcomingEvents:"今後のイベント",
   ongoingProjects:"進行中のプロジェクト",
   projectStatus:"プロジェクト状況",
   supervisor:"スーパーバイザー",
   duration:"期間",
   netWorth:"純資産",
   email:"Eメール",
   phone:"Phone",
   viewAllNotifications:"すべての通知を見る",
   chat:"チャット",
   transactionList:"取引リスト",
   transferReport:"転送レポート",
   expenseCategory:"経費カテゴリ",
   projects: "プロジェクト",
   projectDetails: "プロジェクト詳細",
   reports:"レポート",
   clients: "クライアント",
   search: "サーチ",
   add: "追加する",
   crmProjects: "CRM /プロジェクト",
   projectGrid: "プロジェクトグリッド",
   projectList: "プロジェクト一覧",
   crmProjectDetails: "CRM /プロジェクトの詳細",
   filesUploaded: "アップロードされたファイル",
   statistics: "統計",
   projectGallery: "プロジェクトギャラリー",
   progress: "進捗",
   allClients: "All Clients",
   favourite: "好きな",
   recentlyAdded: "最近追加された",
   crmReports: "CRM /レポート",
   crmClients: "CRM /クライアント",
   dashboardCrypto: "ダッシュボード/暗号",
   crypto: "暗号",
   marketCap: "時価総額",
   wallet: "財布",
   trade: "トレード",
   tradeHistory: "貿易の歴史",
   safeTrade: "安全な貿易",
   exchangeStatistics: "交換統計",
   quickTrade: "クイックトレード",
   recentTrades: "最近の取引",
   exchangeRate: "為替レート",
   coinsList: "コインリスト",
   cryptoMarketCap: "暗号/時価総額",
   cryptoWallet: "暗号/ウォレット",
   cryptoTrade: "暗号/貿易",
   walletAddress: "ウォレットアドレス",
   buyCryptocurrency: "暗号通貨を購入する",
   expenditureStats: "支出統計",
   sellCryptocurrency: "暗号通貨を売る",
   transferCryptocurrency: "暗号通貨の転送",
   bankDetails: "銀行の詳細"
}
//messages.js
export default {
   sendEmail:"Enviar correo electrónico",
   enterYourEmailToSendYouAResetLink:"Ingrese su correo electrónico para enviarle un enlace de reinicio",
   salesReport: "Reporte de ventas",
   totalRevenue: "Los ingresos totales",
   revenueToday: "Ingresos hoy",
   totalOrders: "Total de pedidos",
   newOrdersToday: "Nuevos pedidos hoy",
   userStats: "Estadísticas de usuario",
   salesByCategory: "Ventas por categoría",
   trending: "Tendencias",
   newArticle: "Articulo nuevo",
   today: "Hoy",
   advertiseCampaign: "Campaña de Publicidad",
   bestSeller: "Mejor vendido",
   sales: "Ventas",
   rating: "Clasificación",
   comments: "Comentarios",
   addNewBlogItem: "Añadir nuevo artículo de blog",
   addNew: "Añadir nuevo",
   globalSalesByTopLocation: "Ventas globales por ubicación superior",
   salesTarget: "Objetivo de ventas",
   orders: "Pedidos",
   newVisitors: "Nuevos visitantes",
   subscriptions: "Suscripciones",
   blogTitle: "Titulo de Blog",
   description: "Descripción",
   postBlog: "Publicar blog",
   scheduleLater: "Horario despues",
   recentPosts: "Mensajes recientes",
   browserStats: "Estadísticas del navegador",
   send: "Enviar",
   citiesAndClients: "Ciudades y Clientes",
   connections: "Conexiones",
   couponStats: "Estadísticas de cupones",
   dailySales: "Ventas diarias",
   salesToday: "Ventas hoy",
   expenses: "Gastos",
   totalExpense: "Gasto total",
   inbox: "Bandeja de entrada",
   compose: "Componer",
   all: "Todas",
   unread: "No leído",
   overallTrafficStatus: "Estado general del tráfico",
   whoToFollow: "A quién seguir",
   follow: "Seguir",
   following: "Siguiendo",
   photos: "Las fotos",
   realTimeTraffic: "Tráfico en tiempo real",
   recentComments: "Comentarios Recientes",
   recentOrderAndStatus: "Estado y orden recientes",
   totalSales: "Ventas totales",
   newCustomers: "nuevos clientes",
   totalCustomers: "Clientes Totales",
   skills: "Habilidades",
   likes: "Gustos",
   followers: "Seguidores",
   statistic: "Estadística",
   sold: "Vendido",
   inStock: "En stock",
   amountOfStocksSold: "Cantidad de acciones vendidas",
   viewMore: "Ver más",
   systemReports: "Informes del sistema",
   view: "Ver",
   edit: "Editar",
   delete: "Borrar",
   toDoList: "Lista de quehaceres",
   topBuyers: "Los mejores compradores",
   viewProfile: "Ver perfil",
   sendMessage: "Enviar mensaje",
   articles: "Artículos",
   buyersStats: "Estadísticas de los compradores",
   salesAndVisitorStatus: "Estado de ventas y visitantes",
   websiteStatistics: "Estadísticas del sitio web",
   serverLoad: "Carga del servidor",
   CPULoad: "Carga de la CPU",
   storageRemain: "Almacenamiento restante",
   bandwidthUse: "Uso del ancho de banda",
   recentTestimonial: "Testimonial reciente",
   productStats: "Estadísticas del producto",
   activityTimeline: "Cronología de la actividad",
   themeSettings: "Ajustes de tema",
   chooseThemeColor: "Elija el color del tema",
   boxLayout: "Diseño de la caja",
   darkMode: "Modo oscuro",
   quickLinks: "enlaces rápidos",
   notifications: "Notificaciones",
   system: "Sistema",
   quickResults: "Resultados rápidos",
   account: "Cuenta",
   autoUpdates: "Actualizaciones automáticas",
   offlineMode: "Modo offline",
   locationShare: "Ubicación Compartir",
   showOfflineUser: "Mostrar Usuario Desconectado",
   saveHistory: "Guardar historial",
   todaySales: "Ventas de hoy",
   itemsSold: "cosas vendidas",
   customerTarget: "Objetivo del cliente",
   ofTheMonth: "Del mes",
   myProfile: "Mi perfil",
   calendar: "Calendario",
   myInbox: "Mi bandeja de entrada",
   myTasks: "Mis tareas",
   logOut: "Cerrar sesión",
   lineChart: "Gráfico de linea",
   barChart: "Gráfico de barras",
   doughnutChart: "Tabla de anillos",
   pieChart: "Gráfico circular",
   radarChart: "Carta de radar",
   polarAreaChart: "Tabla de área polar",
   bubbleChart: "Gráfico de burbujas",
   funnelChart: "Gráfico de embudo",
   vue2Dragula: "Vue 2 Dragula",
   vueDraggable: "Vue Draggable",
   draggableResizable: "Tamaño variable arrastrable",
   quillEditor: "Editor de pluma",
   wysiwyg: "WYSIWYG",
   cropImage: "Delimitar imagen",
   previewImage: "Vista previa de la imagen",
   baseConfig: "Config Base",
   audioTrackAndPlaysInline: "Pistas de audio y reproducciones en línea",
   hlsLive: "HLS en vivo",
   listOfAllMaterialIcons: "Lista de todos los iconos de materiales",
   arrowsAndDirectionIcons: "Flechas e iconos de dirección",
   webAppIcons: "Iconos de aplicaciones web",
   controlIcons: "Iconos de control",
   textEditorIcons: "Iconos de editor de texto",
   layoutIcons: "Iconos de diseño",
   brandIcons: "Iconos de marca",
   googleMap: "Mapa de Google",
   leafletMap: "Mapa del folleto",
   choosePlan: "Elegir plan",
   login: "Iniciar sesión",
   enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Introduzca el nombre de usuario y la contraseña para acceder al panel de control de Administrar.",
   rememberMe: "Recuérdame",
   forgotPassword: "¿Se te olvidó tu contraseña?",
   dontHaveAnAccount: "¿No tienes una cuenta?",
   orLoginWith: "O iniciar sesión con",
   registerToAdmin: "Registrarse en Admin",
   createAccount: "Crear una cuenta",
   alreadyHaveAnAccount: "Ya tienes una cuenta ?",
   orRegisterWith: "O registrarse con",
   basicTables: "Tablas basicas",
   tableInverse: "Tabla inversa",
   tableHead: "Cabeza de mesa",
   stripedRows: "Filas de rayas",
   borderedTable: "Mesa bordeada",
   hoverableTable: "Mesa transitable",
   contextualTable: "Tabla contextual",
   responsiveTable: "Tabla de respuesta",
   responsiveTable2: "Tabla de respuesta 2",
   collapse: "Colapso",
   toggleCollapse: "Colapso de palanca",
   toggleInnerCollapse: "Toggle Inner Collapse",
   multipleCollapse: "Colapso múltiple",
   toggleBothCollapseAandB: "Alternar tanto colapso A y B",
   accordion: "Acordeón",
   basicTab: "Pestaña basica",
   closedItem: "Artículo cerrado",
   closedItem2: "Artículo cerrado 2",
   linkColor: "Color de enlace",
   additionalcontentInsideAlerts: "Contenido adicional dentro de las alertas",
   normalAlertsWithDismiss: "Alertas normales con despido",
   autoDismissingAlerts: "Alertas de despido automático",
   showAlertWithCountdownTimer: "Mostrar alerta con temporizador de cuenta regresiva",
   badgeSize: "Tamaño de la insignia",
   badgesColor: "Insignias de color",
   predefinedButtonStyles: "Estilos de botones predefinidos",
   outlineButtons: "Botones de esquema",
   buttonsSizes: "Botones de Tamaños",
   blockLevelButtons: "Botones de nivel de bloque",
   blockLevelButton: "Botón de nivel de bloque",
   dropDownAndDropUp: "DropDown & Dropup",
   carousel: "Carrusel",
   firstSlide: "Primera diapositiva",
   helloWorld: "Hola Mundo",
   basicUsage: "Uso básico",
   alignment: "Alineación",
   simpleProgressBarWithLables: "Barra de progreso simple con etiquetas",
   coloredProgressBars: "Barras de progreso de color",
   stackedBars: "Barras apiladas",
   sizingProressBar: "Barra de progreso de tamaño",
   stripedProgressBar: "Barra de progreso a rayas",
   pillsVariant: "Variante de pastillas",
   advancedExamples: "Ejemplos avanzados",
   previous: "Anterior",
   next: "Siguiente",
   dynamicTabs: "Tabulaciones dinámicas",
   imaToolTip: "Soy una sugerencia!",
   hoverMe: "Encamíname",
   positioning: "Posicionamiento",
   tooltipOnTop: "Información sobre herramientas en la parte superior",
   tooltipOnBottom: "Información sobre herramientas en la parte inferior",
   tooltipOnLeft: "Información sobre herramientas a la izquierda",
   tooltipOnRight: "Información sobre herramientas a la derecha",
   popover: "Popover",
   popoverTitle: "Título de popover",
   popoverPositioning: "Posicionamiento popover",
   popoverOnTop: "Popover en la parte superior",
   popoverOnBottom: "Popover en la parte inferior",
   popoverOnLeft: "Popover a la izquierda",
   popoverOnRight: "Popover a la derecha",
   by: "Por",
   editPost: "Editar post",
   deletePost: "Eliminar mensaje",
   standard: "Estándar",
   forMostOfTheUsers: "Para la mayoría de los usuarios",
   mega: "Mega",
   master: "Dominar",
   forDeveloper: "Para desarrollador",
   forLargeEnterprises: "Para grandes empresas",
   dashboard: "Tablero",
   dashboard1: "Dashboard 1",
   dashboard2: "Dashboard 2",
   uiElements: "Elementos UI",
   alerts: "Las alertas",
   buttons: "Botones",
   progressBar: "Barra de progreso",
   tabs: "Pestañas",
   accordions: "Acordeones",
   pagination: "Paginación",
   tooltip: "Información sobre herramientas",
   badges: "Insignias",
   cards: "Tarjetas",
   editor: "Editor",
   dragAndDrop: "Arrastrar y soltar",
   icons: "Iconos",
   charts: "Cartas",
   tables: "Mesas",
   maps: "Mapas",
   pages: "Páginas",
   users: "Usuarios",
   session: "Sesión",
   features: "Caracteristicas",
   themify: "Temizar",
   material: "Material",
   fontAwesome: "Fuente impresionante",
   vueChartjs: "Vue Chartjs",
   vueEcharts: "Vue Echarts",
   basic: "BASIC",
   responsive: "Sensible",
   googleMaps: "mapas de Google",
   leafletMaps: "Mapas de folletos",
   gallery: "Galería",
   pricing: "Precios",
   blank: "Blanco",
   userProfile: "Perfil del usuario",
   usersList: "Lista de usuarios",
   signUp: "Regístrate",
   lockScreen: "Bloquear pantalla",
   imageCropper: "Recortadora de imagen",
   videoPlayer: "Reproductor de video",
   dropzone: "Zona de descenso",
   reply: "Respuesta",
   dashboardDashboard1: "Dashboard / Dashboard 1",
   dashboardDashboard2: "Dashboard / Dashboard 2",
   uiElementsButtons: "UI-Elementos / Botones",
   uiElementsCalendar: "UI-Elementos / Calendario",
   uiElementsProgressBar: "Elementos de UI / Barra de progreso",
   uiElementsTabs: "UI-Elements / Tabs",
   uiElementsAccordions: "Elementos UI / Acordeones",
   uiElementsPagination: "Elementos UI / Paginación",
   uiElementsTooltip: "UI-Elements / Tooltip",
   uiElementsBadges: "Elementos UI / Insignias",
   uiElementsCards: "UI-Elementos / Tarjetas",
   uiElementsAlerts: "UI-Elementos / Alertas",
   uiElementsCarousel: "UI-Elements / Carrusel",
   editorQuillEditor: "Editor / Quill Editor",
   editorWYSIWYG: "Editor / WYSIWYG",
   dragAndDropVueDraggable: "Arrastrar y soltar / Vue Draggable",
   dragAndDropVue2Dragula: "Arrastrar y soltar / Vue2 Dragula",
   dragAndDropDraggableResizable: "Arrastrar y soltar / arrastrar tamaño ajustable",
   iconsThemify: "Iconos / Themify",
   iconsMaterial: "Iconos / Material",
   iconsFontAwesome: "Iconos / Fuentes Impresionantes",
   chartsVueChartjs: "Cartas / Vue Chartjs",
   chartsVueEcharts: "Cartas / Vue Echarts",
   mapsGoogleMaps: "Mapas / Google Maps",
   mapsLeafletMaps: "Mapas / Leaflet Maps",
   tablesTables: "Tablas / Tablas",
   tablesResponsiveTable: "Tablas / Tabla Responsiva",
   pagesGallery: "Páginas / Galería",
   pagesPricing: "Páginas / Precios",
   pagesBlank: "Páginas / En Blanco",
   usersUserProfile: "Usuarios / Perfil de Usuario",
   usersUsersList: "Lista de Usuarios / Usuarios",
   featuresImageCropper: "Características / Image Cropper",
   featuresVideoPlayer: "Características / Reproductor de video",
   featuresDropzone: "Características / Dropzone",
   crm:"CRM",
   reports:"Informes",
   dashboardCrm: "Tablero / CRM",
   dashboardReports: "Tablero / Informes",
   invoices:"Facturas",
   payments:"Pagos",
   taxRates:"Las tasas de impuestos",
   addTickets:"Añadir entradas",
   visitors:"Visitantes",
   revenue:"Ingresos",
   deals:"Ofertas",
   trade:"Comercio",
   projectStatus:"Estado del proyecto",
   upcomingEvents:"Próximos Eventos",
   ongoingProjects:"Proyectos en marcha",
   projectStatus:"Estado del proyecto",
   supervisor:"Supervisora",
   duration:"Duración",
   netWorth:"Valor neto",
   email:"Email",
   phone:"Teléfono",
   viewAllNotifications:"Ver todas las notificaciones",
   chat:"Charla",
   transactionList:"Lista de transacciones",
   transferReport:"Informe de transferencia",
   expenseCategory:"Categoría de gastos",
   projects: "Proyectos",
   projectDetails: "detalles del proyecto",
   reports:"Informes",
   clients: "Clientela",
   search: "Buscar",
   add: "Añadir",
   crmProjects: "CRM / Proyectos",
   projectGrid: "Proyecto Grid",
   projectList: "Lista de proyectos",
   crmProjectDetails: "CRM / Detalles del proyecto",
   filesUploaded: "Archivos subidos",
   statistics: "Estadística",
   projectGallery: "Galería de proyectos",
   progress: "Progreso",
   allClients: "Todos los clientes",
   favourite: "Favorito",
   recentlyAdded: "Recientemente añadido",
   crmReports: "CRM / Informes",
   crmClients: "CRM / Clientes",
   dashboardCrypto: "Tablero / Cripto",
   crypto: "Cripto",
   marketCap: "Tapa del mercado",
   wallet: "Billetera",
   trade: "Comercio",
   tradeHistory: "Historia del comercio",
   safeTrade: "Comercio seguro",
   exchangeStatistics: "Estadísticas de intercambio",
   quickTrade: "Comercio rapido",
   recentTrades: "Oficios recientes",
   exchangeRate: "Tipo de cambio",
   coinsList: "Lista de monedas",
   cryptoMarketCap: "Crypto / Market Cap",
   cryptoWallet: "Crypto / Wallet",
   cryptoTrade: "Cripto / Comercio",
   walletAddress: "Dirección de billetera",
   buyCryptocurrency: "Comprar moneda criptográfica",
   expenditureStats: "Estadísticas de gastos",
   sellCryptocurrency: "Vender moneda criptográfica",
   transferCryptocurrency: "Transferir moneda criptográfica",
   bankDetails: "Detalles del banco"
}
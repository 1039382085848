//messages.js
export default {
   sendEmail:"Küldjön e-mailt",
   enterYourEmailToSendYouAResetLink:"Írja be az e-mail címét, hogy elküldje Önnek a Reset linket",
   salesReport: "Értékesítési jelentés",
   totalRevenue: "Összes bevétel",
   revenueToday: "Bevételek ma",
   totalOrders: "Teljes rendelések",
   newOrdersToday: "Új rendelések ma",
   userStats: "Felhasználói statisztika",
   salesByCategory: "Értékesítés kategóriák szerint",
   trending: "Felkapott",
   newArticle: "Új cikk",
   today: "Ma",
   advertiseCampaign: "Hirdesse a kampányt",
   bestSeller: "Legjobban eladott",
   sales: "Sales",
   rating: "Értékelés",
   comments: "Hozzászólások",
   addNewBlogItem: "Új blogbejegyzés felvétele",
   addNew: "Új hozzáadása",
   globalSalesByTopLocation: "Globális értékesítés a legjobb helyen",
   salesTarget: "Értékesítési cél",
   orders: "rendelés",
   newVisitors: "Új látogatók",
   subscriptions: "Előfizetői",
   blogTitle: "Blog cím",
   description: "Leírás",
   postBlog: "Blog közzététele",
   scheduleLater: "Ütemezés később",
   recentPosts: "Legutóbbi hozzászólások",
   browserStats: "Böngésző statisztika",
   send: "Elküld",
   citiesAndClients: "Városok és ügyfelek",
   connections: "kapcsolatok",
   couponStats: "Kupon statisztika",
   dailySales: "Napi értékesítés",
   salesToday: "Értékesítés ma",
   expenses: "Költségek",
   totalExpense: "Teljes költség",
   inbox: "Bejövő",
   compose: "Összeállít",
   all: "Minden",
   unread: "Nem olvasott",
   overallTrafficStatus: "Átfogó forgalmi állapot",
   whoToFollow: "Kit kövessen",
   follow: "Kövesse",
   following: "Következő",
   photos: "fotók",
   realTimeTraffic: "Valós idejű forgalom",
   recentComments: "legutóbbi hozzászólások",
   recentOrderAndStatus: "Legutóbbi rendelés és állapot",
   totalSales: "Teljes értékesítés",
   newCustomers: "új vásárlók",
   totalCustomers: "Összes vásárló",
   skills: "szakértelem",
   likes: "Kedveltek",
   followers: "Követő",
   statistic: "Statisztikai",
   sold: "Eladott",
   inStock: "Raktáron",
   amountOfStocksSold: "Az eladott készletek összege",
   viewMore: "Mutass többet",
   systemReports: "Rendszerjelentések",
   view: "Kilátás",
   edit: "szerkesztése",
   delete: "Töröl",
   toDoList: "Feladatlista",
   topBuyers: "Legjobb vevők",
   viewProfile: "Profil megtekintése",
   sendMessage: "Üzenet küldése",
   articles: "Cikkek",
   buyersStats: "Vásárlók statisztikái",
   salesAndVisitorStatus: "Értékesítési és látogatói státusza",
   websiteStatistics: "Webhelystatisztika",
   serverLoad: "Szerver betöltése",
   CPULoad: "CPU terhelés",
   storageRemain: "Tárolás megmarad",
   bandwidthUse: "Sávszélesség használata",
   recentTestimonial: "Legutóbbi bizonyság",
   productStats: "Termékstatisztikák",
   activityTimeline: "Tevékenység idővonal",
   themeSettings: "Téma beállítások",
   chooseThemeColor: "Válassza ki a téma színét",
   boxLayout: "Doboz elrendezés",
   darkMode: "Sötét mód",
   quickLinks: "Gyors linkek",
   notifications: "értesítések",
   system: "Rendszer",
   quickResults: "Gyors eredmény",
   account: "számla",
   autoUpdates: "Automatikus frissítések",
   offlineMode: "Offline mód",
   locationShare: "Helymegosztás",
   showOfflineUser: "Offline felhasználó megjelenítése",
   saveHistory: "Mentés mentése",
   todaySales: "Ma Értékesítés",
   itemsSold: "eladott tárgyak",
   customerTarget: "Ügyfél cél",
   ofTheMonth: "a hónap",
   myProfile: "A profilom",
   calendar: "Naptár",
   myInbox: "Bejövõ postafiókom",
   myTasks: "Saját feladatok",
   logOut: "Kijelentkezés",
   lineChart: "Vonalrajz",
   barChart: "Oszlopdiagram",
   doughnutChart: "Donut Chart",
   pieChart: "Kördiagram",
   radarChart: "Radar diagram",
   polarAreaChart: "Polar terület diagram",
   bubbleChart: "Bubble Chart",
   funnelChart: "Tölcsér-diagram",
   vue2Dragula: "Vue 2 Dragula",
   vueDraggable: "Vue Draggable",
   draggableResizable: "Húzható átméretezhető",
   quillEditor: "Quill szerkesztő",
   wysiwyg: "WYSIWYG",
   cropImage: "Crop kép",
   previewImage: "Kép előnézete",
   baseConfig: "Base Config",
   audioTrackAndPlaysInline: "Hangsáv és inlay lejátszás",
   hlsLive: "HLS Live",
   listOfAllMaterialIcons: "Az összes anyagi ikon",
   arrowsAndDirectionIcons: "Nyilak és irány ikonok",
   webAppIcons: "Webalkalmazás ikonok",
   controlIcons: "Vezérlő ikonok",
   textEditorIcons: "Szövegszerkesztő ikonok",
   layoutIcons: "Elrendezési ikonok",
   brandIcons: "Márkás ikonok",
   googleMap: "Google Térkép",
   leafletMap: "Bemutató térkép",
   choosePlan: "Válassza ki a Tervet",
   login: "Belépés",
   enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Adja meg a felhasználónevet és a jelszót az Adminify vezérlőpultjának eléréséhez.",
   rememberMe: "Emlékezz rám",
   forgotPassword: "Elfelejtett jelszó?",
   dontHaveAnAccount: "Nincs fiókod?",
   orLoginWith: "Vagy jelentkezzen be",
   registerToAdmin: "Regisztráljon Admin",
   createAccount: "Fiók létrehozása",
   alreadyHaveAnAccount: "Már van fiókja ?",
   orRegisterWith: "Vagy regisztráljon",
   basicTables: "Alapvető táblázatok",
   tableInverse: "Táblázat inverz",
   tableHead: "Táblázatfej",
   stripedRows: "Csíkos sorok",
   borderedTable: "Határolt tábla",
   hoverableTable: "Lebegtethető táblázat",
   contextualTable: "Kontextusos táblázat",
   responsiveTable: "Érzékeny tábla",
   responsiveTable2: "Érzékeny 2. táblázat",
   collapse: "Összeomlás",
   toggleCollapse: "Toggle Collapse",
   toggleInnerCollapse: "A belső összeomlás megváltoztatása",
   multipleCollapse: "Többszörös összeomlás",
   toggleBothCollapseAandB: "Toggle Mind A, mind a B összecsukása",
   accordion: "Harmonika",
   basicTab: "Alapvető fül",
   closedItem: "Zárt tétel",
   closedItem2: "Zárt tétel 2",
   linkColor: "Link szín",
   additionalcontentInsideAlerts: "További tartalmi figyelmeztetések",
   normalAlertsWithDismiss: "Normál riasztások elutasítással",
   autoDismissingAlerts: "Automatikusan figyelmen kívül hagyó figyelmeztetések",
   showAlertWithCountdownTimer: "Figyelmeztetés a visszaszámlálóval",
   badgeSize: "Jelvényméret",
   badgesColor: "Színjelvények",
   predefinedButtonStyles: "Előre meghatározott gombstílusok",
   outlineButtons: "Outline gombok",
   buttonsSizes: "Gombok méretei",
   blockLevelButtons: "Blokkszintű gombok",
   blockLevelButton: "Blokkszintű gomb",
   dropDownAndDropUp: "DropDown & Dropup",
   carousel: "Körhinta",
   firstSlide: "Első dia",
   helloWorld: "Helló Világ",
   basicUsage: "Alapvető használat",
   alignment: "igazítás",
   simpleProgressBarWithLables: "Egyszerű előrehaladási sáv címkével",
   coloredProgressBars: "Színes előrehaladtávok",
   stackedBars: "Halmozott bárok",
   sizingProressBar: "A Progress Bar méretezése",
   stripedProgressBar: "Striped Progress Bar",
   pillsVariant: "Pills Variant",
   advancedExamples: "Haladó példák",
   previous: "Előző",
   next: "Következő",
   dynamicTabs: "Dinamikus lapok",
   imaToolTip: "Én egy eszközleírás!",
   hoverMe: "Hover Me",
   positioning: "Positioning",
   tooltipOnTop: "Tooltip a lap tetejére",
   tooltipOnBottom: "Tooltip az alján",
   tooltipOnLeft: "Tooltip balra",
   tooltipOnRight: "Tooltip jobbra",
   popover: "Popover",
   popoverTitle: "Popover cím",
   popoverPositioning: "Popover pozicionálás",
   popoverOnTop: "Popover a tetején",
   popoverOnBottom: "Popover az aljára",
   popoverOnLeft: "Popover a bal oldalon",
   popoverOnRight: "Popover jobbra",
   by: "Által",
   editPost: "Posta szerkesztése",
   deletePost: "Törölje a bejegyzést",
   standard: "Alapértelmezett",
   forMostOfTheUsers: "A legtöbb felhasználó számára",
   mega: "Mega",
   master: "Fő",
   forDeveloper: "Fejlesztőnek",
   forLargeEnterprises: "Nagyvállalatok számára",
   dashboard: "Irányítópult",
   dashboard1: "Irányítópult 1",
   dashboard2: "Irányítópult 2",
   uiElements: "UI elemek",
   alerts: "figyelmeztetések",
   buttons: "gombok",
   progressBar: "Fejlődésmutató",
   tabs: "Lapok",
   accordions: "harmonikák",
   pagination: "Lapszámozás",
   tooltip: "Tooltip",
   badges: "Kitűzők",
   cards: "kártyák",
   editor: "Szerkesztő",
   dragAndDrop: "Drag and Drop",
   icons: "Ikonok",
   charts: "táblázatok",
   tables: "asztalok",
   maps: "Maps",
   pages: "Oldalak",
   users: "felhasználók",
   session: "Ülés",
   features: "Jellemzők",
   themify: "Themify",
   material: "Anyag",
   fontAwesome: "Font Awesome",
   vueChartjs: "Vue Charts",
   vueEcharts: "Vue Echarts",
   basic: "Alapvető",
   responsive: "Fogékony",
   googleMaps: "Google térkép",
   leafletMaps: "Leaflet Térképek",
   gallery: "Képtár",
   pricing: "Árazás",
   blank: "Üres",
   userProfile: "Felhasználói profil",
   usersList: "Felhasználók listája",
   signUp: "Regisztrálj",
   lockScreen: "Zárolás képernyő",
   imageCropper: "Kép Cropper",
   videoPlayer: "Videó lejátszó",
   dropzone: "Dropzone",
   reply: "Válasz",
   dashboardDashboard1: "Műszerfal / műszerfal 1",
   dashboardDashboard2: "Műszerfal / műszerfal 2",
   uiElementsButtons: "UI elemek / gombok",
   uiElementsCalendar: "UI elemek / Naptár",
   uiElementsProgressBar: "UI elemek / előrehaladási sáv",
   uiElementsTabs: "UI elemek / fülek",
   uiElementsAccordions: "UI elemek / hangszerek",
   uiElementsPagination: "UI-elemek / lapozás",
   uiElementsTooltip: "UI elemek / Tooltip",
   uiElementsBadges: "UI elemek / jelvények",
   uiElementsCards: "UI-elemek / kártyák",
   uiElementsAlerts: "UI elemek / riasztások",
   uiElementsCarousel: "UI-elemek / körhinta",
   editorQuillEditor: "Szerkesztő / Quill szerkesztő",
   editorWYSIWYG: "Szerkesztő / WYSIWYG",
   dragAndDropVueDraggable: "Drag and Drop / Vue Draggable",
   dragAndDropVue2Dragula: "Drag and Drop / Vue2 Dragula",
   dragAndDropDraggableResizable: "Drag and Drop / Draggable átméretezhető",
   iconsThemify: "Ikonok / Themify",
   iconsMaterial: "Ikonok / Anyagok",
   iconsFontAwesome: "Ikonok / Félelmetes karakter",
   chartsVueChartjs: "Grafikonok / Vue Charts",
   chartsVueEcharts: "Diagramok / Vue Echarts",
   mapsGoogleMaps: "Térképek / Google Térkép",
   mapsLeafletMaps: "Térképek / Kézikönyvek Térképek",
   tablesTables: "Táblázatok / táblázatok",
   tablesResponsiveTable: "Táblázatok / Érzékeny tábla",
   pagesGallery: "Oldalak / Galéria",
   pagesPricing: "Oldalak / árképzés",
   pagesBlank: "Oldalak / üres",
   usersUserProfile: "Felhasználók / Felhasználói profil",
   usersUsersList: "Felhasználók / felhasználók listája",
   featuresImageCropper: "Jellemzők / Kép Cropper",
   featuresVideoPlayer: "Jellemzők / videó lejátszó",
   featuresDropzone: "Tulajdonságok / Dropzone",
   crm:"CRM",
   reports:"jelentések",
   dashboardCrm: "Irányítópult / CRM",
   dashboardReports: "Irányítópult / jelentések",
   invoices:"számlák",
   payments:"kifizetések",
   taxRates:"Adókulcsok",
   addTickets:"Jegyek hozzáadása",
   visitors:"Látogatók",
   revenue:"jövedelem",
   deals:"Deals",
   projectStatus:"Projekt állapota",
   upcomingEvents:"Közelgő események",
   ongoingProjects:"Folyamatban lévő projektek",
   supervisor:"Felügyelő",
   duration:"tartam",
   netWorth:"Nettó érték",
   email:"Email",
   phone:"Telefon",
   viewAllNotifications:"Az összes értesítés megtekintése",
   chat:"Csevegés",
   transactionList:"Tranzakciós lista",
   transferReport:"Átviteli jelentés",
   expenseCategory:"Költségkategória",
   projects: "projektek",
   projectDetails: "A projekt részletei",
   reports:"jelentések",
   clients: "Ügyfeleink",
   search: "Keresés",
   add: "hozzáad",
   crmProjects: "CRM / projektek",
   projectGrid: "Projekt Grid",
   projectList: "Projektlista",
   crmProjectDetails: "CRM / Projekt részletek",
   filesUploaded: "Feltöltött fájlok",
   statistics: "Statisztika",
   projectGallery: "Projekt Galéria",
   progress: "Haladás",
   allClients: "Minden ügyfél",
   favourite: "Kedvenc",
   recentlyAdded: "Nemrég hozzáadott",
   crmReports: "CRM / Jelentések",
   crmClients: "CRM / Ügyfelek",
   dashboardCrypto: "Műszerfal / Crypto",
   crypto: "Crypto",
   marketCap: "Piaci sapka",
   wallet: "Pénztárca",
   trade: "Kereskedelmi",
   tradeHistory: "Kereskedelmi történet",
   safeTrade: "Biztonságos kereskedelem",
   exchangeStatistics: "Csere statisztikák",
   quickTrade: "Gyors kereskedelem",
   recentTrades: "Legutóbbi kereskedések",
   exchangeRate: "Árfolyam",
   coinsList: "Érmék listája",
   cryptoMarketCap: "Kripto / Market Cap",
   cryptoWallet: "Crypto / pénztárca",
   cryptoTrade: "Kripto / kereskedelem",
   walletAddress: "Pénztárca címe",
   buyCryptocurrency: "Crypto valuta vásárlása",
   expenditureStats: "Kiadási statisztika",
   sellCryptocurrency: "Crypto valuta elad",
   transferCryptocurrency: "Kripto valuta átutalása",
   bankDetails: "Banki adatok"

}
//messages.js
export default {
  sendEmail:"发送电子邮件",
  enterYourEmailToSendYouAResetLink:"输入您的电子邮件，向您发送重置链接",
  salesReport: "销售报告",
  totalRevenue: "总收入",
  revenueToday: "今日收入",
  totalOrders: "总订单",
  newOrdersToday: "今日新订单",
  userStats: "用户统计",
  salesByCategory: "按类别销售",
  trending: "趋势",
  newArticle: "新文章",
  today: "今天",
  advertiseCampaign: "广告活动",
  bestSeller: "畅销书",
  sales: "销售",
  rating: "评分",
  comments: "注释",
  addNewBlogItem: "添加新博客项目",
  addNew: "添新",
  globalSalesByTopLocation: "顶级位置的全球销售额",
  salesTarget: "销售目标",
  orders: "命令",
  newVisitors: "新访客",
  subscriptions: "订阅",
  blogTitle: "博客标题",
  description: "描述",
  postBlog: "发布博客",
  scheduleLater: "稍后安排",
  recentPosts: "最近的帖子",
  browserStats: "浏览器统计信息",
  send: "发送",
  citiesAndClients: "城市和客户",
  connections: "连接",
  couponStats: "优惠券统计",
  dailySales: "每日销售",
  salesToday: "今日销售",
  expenses: "花费",
  totalExpense: "总费用",
  inbox: "收件箱",
  compose: "撰写",
  all: "所有",
  unread: "未读",
  overallTrafficStatus: "整体交通状况",
  whoToFollow: "关注谁",
  follow: "跟随",
  following: "以下",
  photos: "相片",
  realTimeTraffic: "实时交通",
  recentComments: "最近的评论",
  recentOrderAndStatus: "最近的订单和状态",
  totalSales: "总销售额",
  newCustomers: "新客户",
  totalCustomers: "客户总数",
  skills: "技能",
  likes: "喜欢",
  followers: "关注",
  statistic: "统计",
  sold: "出售",
  inStock: "有现货",
  amountOfStocksSold: "出售的股票数量",
  viewMore: "查看更多",
  systemReports: "系统报告",
  view: "视图",
  edit: "编辑",
  delete: "删除",
  toDoList: "待办事项清单",
  topBuyers: "顶级买家",
  viewProfile: "查看资料",
  sendMessage: "发信息",
  articles: "用品",
  buyersStats: "买家统计",
  salesAndVisitorStatus: "销售和访客身份",
  websiteStatistics: "网站统计",
  serverLoad: "服务器负载",
  CPULoad: "CPU负载",
  storageRemain: "存储保持不变",
  bandwidthUse: "带宽使用",
  recentTestimonial: "最近的见证",
  productStats: "产品统计",
  activityTimeline: "活动时间表",
  themeSettings: "主题设置",
  chooseThemeColor: "选择主题颜色",
  boxLayout: "框布局",
  darkMode: "暗模式",
  quickLinks: "快速链接",
  notifications: "通知",
  system: "系统",
  quickResults: "快速结果",
  account: "帐户",
  autoUpdates: "自动更新",
  offlineMode: "离线模式",
  locationShare: "位置分享",
  showOfflineUser: "显示离线用户",
  saveHistory: "保存历史",
  todaySales: "今日销售",
  itemsSold: "已售出的商品",
  customerTarget: "客户目标",
  ofTheMonth: "这个月",
  myProfile: "我的简历",
  calendar: "日历",
  myInbox: "我的收件箱",
  myTasks: "我的任务",
  logOut: "登出",
  lineChart: "折线图",
  barChart: "条形图",
  doughnutChart: "甜甜圈图表",
  pieChart: "饼形图",
  radarChart: "雷达图",
  polarAreaChart: "极地图",
  bubbleChart: "气泡图",
  funnelChart: "漏斗图",
  vue2Dragula: "Vue 2 Dragula",
  vueDraggable: "Vue Draggable",
  draggableResizable: "可拖动的可调整大小",
  quillEditor: "羽毛笔编辑器",
  wysiwyg: "所见即所得",
  cropImage: "裁剪图像",
  previewImage: "预览图片",
  baseConfig: "基本配置",
  audioTrackAndPlaysInline: "音轨和播放内联",
  hlsLive: "HLS Live",
  listOfAllMaterialIcons: "所有材料图标列表",
  arrowsAndDirectionIcons: "箭头和方向图标",
  webAppIcons: "Web应用程序图标",
  controlIcons: "控制图标",
  textEditorIcons: "文本编辑器图标",
  layoutIcons: "布局图标",
  brandIcons: "品牌图标",
  googleMap: "谷歌地图",
  leafletMap: "宣传单地图",
  choosePlan: "选择计划",
  login: "登录",
  enterUsernameAndPasswordToAccessControlPanelOfAdminify: "输入用户名和密码以访问Adminify的控制面板。",
  rememberMe: "记住我",
  forgotPassword: "忘记密码？",
  dontHaveAnAccount: "没有账号？",
  orLoginWith: "或登录",
  registerToAdmin: "注册管理员",
  createAccount: "创建帐号",
  alreadyHaveAnAccount: "已经有账号？",
  orRegisterWith: "或注册",
  basicTables: "基本表格",
  tableInverse: "表反向",
  tableHead: "表头",
  stripedRows: "条纹行",
  borderedTable: "边界表",
  hoverableTable: "可怜表",
  contextualTable: "上下文表",
  responsiveTable: "响应表",
  responsiveTable2: "响应表2",
  collapse: "坍方",
  toggleCollapse: "切换折叠",
  toggleInnerCollapse: "切换内部折叠",
  multipleCollapse: "多次折叠",
  toggleBothCollapseAandB: "切换两者折叠A和B.",
  accordion: "手风琴",
  basicTab: "基本标签",
  closedItem: "封闭物品",
  closedItem2: "已结束的项目2",
  linkColor: "链接颜色",
  additionalcontentInsideAlerts: "警报内的其他内容",
  normalAlertsWithDismiss: "正常警报与解雇",
  autoDismissingAlerts: "自动解除警报",
  showAlertWithCountdownTimer: "使用倒计时器显示警报",
  badgeSize: "徽章大小",
  badgesColor: "徽章颜色",
  predefinedButtonStyles: "预定义的按钮样式",
  outlineButtons: "大纲按钮",
  buttonsSizes: "按钮大小",
  blockLevelButtons: "块级按钮",
  blockLevelButton: "块级按钮",
  dropDownAndDropUp: "DropDown和Dropup",
  carousel: "圆盘传送带",
  firstSlide: "第一张幻灯片",
  helloWorld: "你好，世界",
  basicUsage: "基本用法",
  alignment: "对准",
  simpleProgressBarWithLables: "简单的进度栏与标签",
  coloredProgressBars: "彩色进度条",
  stackedBars: "堆积的酒吧",
  sizingProressBar: "调整进度条",
  stripedProgressBar: "条纹进度条",
  pillsVariant: "丸变体",
  advancedExamples: "高级示例",
  previous: "以前",
  next: "下一个",
  dynamicTabs: "动态标签",
  imaToolTip: "我是一个工具提示！",
  hoverMe: "翱翔我",
  positioning: "定位",
  tooltipOnTop: "工具提示在上面",
  tooltipOnBottom: "工具提示在底部",
  tooltipOnLeft: "左侧工具提示",
  tooltipOnRight: "右侧工具提示",
  popover: "酥料饼",
  popoverTitle: "Popover标题",
  popoverPositioning: "Popover定位",
  popoverOnTop: "Popover在上面",
  popoverOnBottom: "在底部弹出",
  popoverOnLeft: "左边的弹出",
  popoverOnRight: "Popover On Right",
  by: "通过",
  editPost: "编辑帖子",
  deletePost: "删除帖子",
  standard: "标准",
  forMostOfTheUsers: "对于大多数用户",
  mega: "兆丰",
  master: "主",
  forDeveloper: "对于开发者",
  forLargeEnterprises: "适用于大型企业",
  dashboard: "仪表板",
  dashboard1: "仪表板1",
  dashboard2: "仪表板2",
  uiElements: "UI元素",
  alerts: "警报",
  buttons: "纽扣",
  progressBar: "进度条",
  tabs: "标签",
  accordions: "手风琴",
  pagination: "分页",
  tooltip: "提示",
  badges: "徽章",
  cards: "牌",
  editor: "编辑",
  dragAndDrop: "拖放",
  icons: "图标",
  charts: "图表",
  tables: "表",
  maps: "地图",
  pages: "网页",
  users: "用户",
  session: "会议",
  features: "特征",
  themify: "Themify",
  material: "材料",
  fontAwesome: "字体真棒",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  basic: "基本",
  responsive: "响应",
  googleMaps: "谷歌地图",
  leafletMaps: "宣传单地图",
  gallery: "画廊",
  pricing: "价钱",
  blank: "空白",
  userProfile: "用户资料",
  usersList: "用户列表",
  signUp: "注册",
  lockScreen: "锁屏",
  imageCropper: "图像裁剪器",
  videoPlayer: "视频播放器",
  dropzone: "拖放区",
  reply: "回复",
  dashboardDashboard1: "仪表板/仪表板1",
  dashboardDashboard2: "仪表板/仪表板2",
  uiElementsButtons: "UI元素/按钮",
  uiElementsCalendar:"日历",
  uiElementsProgressBar: "UI元素/进度条",
  uiElementsTabs: "UI元素/标签",
  uiElementsAccordions: "UI元素/手风琴",
  uiElementsPagination: "UI元素/分页",
  uiElementsTooltip: "UI元素/工具提示",
  uiElementsBadges: "UI元素/徽章",
  uiElementsCards: "UI元素/卡片",
  uiElementsAlerts: "UI元素/警报",
  uiElementsCarousel: "UI元素/轮播",
  editorQuillEditor: "编辑/ Quill编辑",
  editorWYSIWYG: "编辑/所见即所得",
  dragAndDropVueDraggable: "拖放/ Vue可拖动",
  dragAndDropVue2Dragula: "拖放/ Vue2 Dragula",
  dragAndDropDraggableResizable: "拖放/拖拽可调整大小",
  iconsThemify: "图标/ Themify",
  iconsMaterial: "图标/材料",
  iconsFontAwesome: "图标/字体真棒",
  chartsVueChartjs: "图表/ Vue Chartjs",
  chartsVueEcharts: "图表/ Vue Echarts",
  mapsGoogleMaps: "地图/谷歌地图",
  mapsLeafletMaps: "地图/传单地图",
  tablesTables: "表/表",
  tablesResponsiveTable: "表/响应表",
  pagesGallery: "页面/图库",
  pagesPricing: "页面/定价",
  pagesBlank: "页/空白",
  usersUserProfile: "用户/用户档案",
  usersUsersList: "用户/用户列表",
  featuresImageCropper: "功能/图像裁剪",
  featuresVideoPlayer: "功能/视频播放器",
  featuresDropzone: "功能/ Dropzone",
  crm:"CRM",
  dashboardCrm: "仪表板/ CRM",
  dashboardReports: "仪表板 / 报告",
  invoices:"发票",
  payments:"支付",
  taxRates:"税率",
  addTickets:"添加门票",
  visitors:"游客",
  revenue:"收入",
  deals:"交易",
  projectStatus:"项目状态",
  upcomingEvents:"接下来的活动",
  ongoingProjects:"正在进行的项目",
  supervisor:"监",
  duration:"持续时间",
  netWorth:"净值",
  email:"电子邮件",
  phone:"电话",
  viewAllNotifications:"查看所有通知",
  chat:"聊",
  transactionList:"交易清单",
  transferReport:"转移报告",
  expenseCategory:"费用类别",
  projects: "项目",
  projectDetails: "项目细节",
  reports:"报告",
  clients: "客户",
  search: "搜索",
  add: "加",
  crmProjects: "CRM /项目",
  projectGrid: "项目网格",
  projectList: "项目清单",
  crmProjectDetails: "CRM /项目细节",
  filesUploaded: "文件已上传",
  statistics: "统计",
  projectGallery: "项目图库",
  progress: "进展",
  allClients: "所有客户",
  favourite: "喜爱",
  recentlyAdded: "最近添加的",
  crmReports: "CRM /报告",
  crmClients: "CRM /客户",
  dashboardCrypto: "仪表板/加密",
  crypto: "加密",
  marketCap: "市值",
  wallet: "钱包",
  trade: "贸易",  
  tradeHistory: "贸易史",
  safeTrade: "安全贸易",
  exchangeStatistics: "交换统计",
  quickTrade: "快速交易",
  recentTrades: "近期交易",
  exchangeRate: "汇率",
  coinsList: "硬币清单",
  cryptoMarketCap: "加密/市值上限",
  cryptoWallet: "加密/钱包",
  cryptoTrade: "加密/贸易",
  walletAddress: "钱包地址",
  buyCryptocurrency: "购买加密货币", 
  expenditureStats: "支出统计",
  sellCryptocurrency: "出售加密货币",
  transferCryptocurrency: "转移加密货币",
  bankDetails: "银行明细"
}

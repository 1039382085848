//messages.js
export default {
  sendEmail:"Отправить электронное письмо",
  enterYourEmailToSendYouAResetLink:"Введите адрес электронной почты, чтобы отправить ссылку на сброс",
  salesReport: "Отчет о продажах",
  totalRevenue: "Общий доход",
  revenueToday: "Доход сегодня",
  totalOrders: "Всего заказов",
  newOrdersToday: "Новые заказы сегодня",
  userStats: "Статистика пользователя",
  salesByCategory: "Продажи по категориям",
  trending: "Trending",
  newArticle: "Новая статья",
  today: "Cегодня",
  advertiseCampaign: "Рекламная кампания",
  bestSeller: "Бестселлер",
  sales: "Продажи",
  rating: "Рейтинг",
  comments: "Комментарии",
  addNewBlogItem: "Добавить новый элемент блога",
  addNew: "Добавить новое",
  globalSalesByTopLocation: "Глобальные продажи по верхнему местоположению",
  salesTarget: "Объект скидок",
  orders: "заказы",
  newVisitors: "Новые посетители",
  subscriptions: "Подписки",
  blogTitle: "Название блога",
  description: "Описание",
  postBlog: "Почтовый блог",
  scheduleLater: "Позднее",
  recentPosts: "Недавние Посты",
  browserStats: "Статистика браузера",
  send: "послать",
  citiesAndClients: "Города и клиенты",
  connections: "связи",
  couponStats: "Купон Статистика",
  dailySales: "Ежедневные продажи",
  salesToday: "Продажи сегодня",
  expenses: "Затраты",
  totalExpense: "Общий расход",
  inbox: "входящие",
  compose: "компоновать",
  all: "Все",
  unread: "Не прочитано",
  overallTrafficStatus: "Общее состояние трафика",
  whoToFollow: "Кто последует",
  follow: "следить",
  following: "Следующий",
  photos: "Фото",
  realTimeTraffic: "Движение в реальном времени",
  recentComments: "недавние комментарии",
  recentOrderAndStatus: "Последний заказ и статус",
  totalSales: "Тотальная распродажа",
  newCustomers: "новые клиенты",
  totalCustomers: "Всего клиентов",
  skills: "Навыки",
  likes: "Нравится",
  followers: "Читают",
  statistic: "статистика",
  sold: "Продан",
  inStock: "В наличии",
  amountOfStocksSold: "Количество проданных запасов",
  viewMore: "Просмотреть больше",
  systemReports: "Системные отчеты",
  view: "Посмотреть",
  edit: "редактировать",
  delete: "Удалить",
  toDoList: "Список дел",
  topBuyers: "Лучшие покупатели",
  viewProfile: "Просмотреть профиль",
  sendMessage: "Отправить сообщение",
  articles: "статьи",
  buyersStats: "Статистика покупателей",
  salesAndVisitorStatus: "Статус продаж и посетителей",
  websiteStatistics: "Статистика сайта",
  serverLoad: "Загрузка сервера",
  CPULoad: "Загрузка процессора",
  storageRemain: "Хранение осталось",
  bandwidthUse: "Использование полосы пропускания",
  recentTestimonial: "Последние отзывы",
  productStats: "Статистика продукта",
  activityTimeline: "Сроки выполнения",
  themeSettings: "Настройки темы",
  chooseThemeColor: "Выберите цвет темы",
  boxLayout: "Макет коробки",
  darkMode: "Темный режим",
  quickLinks: "Быстрые ссылки",
  notifications: "Уведомления",
  system: "система",
  quickResults: "Быстрые результаты",
  account: "Счет",
  autoUpdates: "Автообновление",
  offlineMode: "Автономный режим",
  locationShare: "Расположение",
  showOfflineUser: "Показать пользователя",
  saveHistory: "Сохранить историю",
  todaySales: "Сегодня продажи",
  itemsSold: "проданные товары",
  customerTarget: "Клиентская цель",
  ofTheMonth: "месяца",
  myProfile: "Мой профайл",
  calendar: "Календарь",
  myInbox: "Мой почтовый ящик",
  myTasks: "Мои задачи",
  logOut: "Выйти",
  lineChart: "Линейный график",
  barChart: "Барная диаграмма",
  doughnutChart: "Диаграмма пончика",
  pieChart: "Круговая диаграмма",
  radarChart: "Радарная диаграмма",
  polarAreaChart: "Полярная площадь",
  bubbleChart: "Диаграмма пузырьков",
  funnelChart: "График воронки",
  vue2Dragula: "Vue 2 Dragula",
  vueDraggable: "Vue Draggable",
  draggableResizable: "Draggable Resizable",
  quillEditor: "Редактор Quill",
  wysiwyg: "WYSIWYG",
  cropImage: "Обрезать изображение",
  previewImage: "Предварительный просмотр",
  baseConfig: "Базовая конфигурация",
  audioTrackAndPlaysInline: "Аудиодорожка и воспроизведение Inline",
  hlsLive: "HLS Live",
  listOfAllMaterialIcons: "Список всех материалов Icons",
  arrowsAndDirectionIcons: "Значки стрелок и направлений",
  webAppIcons: "Иконки веб-приложений",
  controlIcons: "Иконки управления",
  textEditorIcons: "Иконки текстового редактора",
  layoutIcons: "Иконки компоновки",
  brandIcons: "Иконки бренда",
  googleMap: "Google Map",
  leafletMap: "Карта проспектов",
  choosePlan: "Выберите План",
  login: "Авторизоваться",
  enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Введите имя пользователя и пароль для доступа к панели управления Adminify.",
  rememberMe: "Запомни меня",
  forgotPassword: "Забыли пароль?",
  dontHaveAnAccount: "У вас нет учетной записи?",
  orLoginWith: "Или Войти с",
  registerToAdmin: "Регистрация для администратора",
  createAccount: "Регистрация",
  alreadyHaveAnAccount: "У вас уже есть учетная запись?",
  orRegisterWith: "Или зарегистрируйтесь",
  basicTables: "Основные таблицы",
  tableInverse: "Таблица инверсии",
  tableHead: "Головка стола",
  stripedRows: "Полосатые ряды",
  borderedTable: "Граничная таблица",
  hoverableTable: "Жидкая таблица",
  contextualTable: "Контекстная таблица",
  responsiveTable: "Отзывчивая таблица",
  responsiveTable2: "Отзывчивая таблица 2",
  collapse: "коллапс",
  toggleCollapse: "Свернуть Свернуть",
  toggleInnerCollapse: "Переключить внутренний сбой",
  multipleCollapse: "Множественный крах",
  toggleBothCollapseAandB: "Переключить оба Свернуть A и B",
  accordion: "аккордеон",
  basicTab: "Основная вкладка",
  closedItem: "Закрытый предмет",
  closedItem2: "Закрытый пункт 2",
  linkColor: "Цвет ссылки",
  additionalcontentInsideAlerts: "Оглавление",
  normalAlertsWithDismiss: "Нормальные предупреждения с увольнением",
  autoDismissingAlerts: "Автоотклонения",
  showAlertWithCountdownTimer: "Показывать оповещение с таймером обратного отсчета",
  badgeSize: "Размер значка",
  badgesColor: "Цвет значков",
  predefinedButtonStyles: "Предопределенные стили кнопок",
  outlineButtons: "Кнопки контура",
  buttonsSizes: "Размеры кнопок",
  blockLevelButtons: "Кнопки уровня блока",
  blockLevelButton: "Кнопка уровня блока",
  dropDownAndDropUp: "DropDown & Dropup",
  carousel: "Карусель",
  firstSlide: "Первый слайд",
  helloWorld: "Привет мир",
  basicUsage: "Основное использование",
  alignment: "центровка",
  simpleProgressBarWithLables: "Простой индикатор прогресса с ярлыками",
  coloredProgressBars: "Цветные прожекторы",
  stackedBars: "Штабелированные бары",
  sizingProressBar: "Шкала прогресса калибровки",
  stripedProgressBar: "Полоса пропускания полос",
  pillsVariant: "Варианты таблеток",
  advancedExamples: "Расширенные примеры",
  previous: "предыдущий",
  next: "следующий",
  dynamicTabs: "Динамические вкладки",
  imaToolTip: "Я - всплывающая подсказка!",
  hoverMe: "Hover Me",
  positioning: "позиционирование",
  tooltipOnTop: "Всплывающая подсказка",
  tooltipOnBottom: "Подсказка",
  tooltipOnLeft: "Подсказка слева",
  tooltipOnRight: "Подсказка вправо",
  popover: "Трещать",
  popoverTitle: "Имя Popover",
  popoverPositioning: "Попоровское позиционирование",
  popoverOnTop: "Наполеон",
  popoverOnBottom: "Поповер на дне",
  popoverOnLeft: "Поповар слева",
  popoverOnRight: "Поповер справа",
  by: "От",
  editPost: "Редактировать сообщение",
  deletePost: "Удалить сообщение",
  standard: "стандарт",
  forMostOfTheUsers: "Для большинства пользователей",
  mega: "Мега",
  master: "Мастер",
  forDeveloper: "Для разработчиков",
  forLargeEnterprises: "Для крупных предприятий",
  dashboard: "Панель приборов",
  dashboard1: "Панель приборов 1",
  dashboard2: "Панель приборов 2",
  uiElements: "Элементы пользовательского интерфейса",
  alerts: "Оповещения",
  buttons: "Кнопки",
  progressBar: "Индикатор",
  tabs: "Вкладки",
  accordions: "Аккордеоны",
  pagination: "пагинация",
  tooltip: "подсказка",
  badges: "Значки",
  cards: "Карты",
  editor: "редактор",
  dragAndDrop: "Перетаскивание",
  icons: "Иконки",
  charts: "Графики",
  tables: "таблицы",
  maps: "Карты",
  pages: "страницы",
  users: "пользователей",
  session: "сессия",
  features: "Особенности",
  themify: "Themify",
  material: "материал",
  fontAwesome: "Шрифт Awesome",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  basic: "основной",
  responsive: "отзывчивый",
  googleMaps: "Карты Гугл",
  leafletMaps: "Листовые карты",
  gallery: "Галерея",
  pricing: "ценообразование",
  blank: "пустой",
  userProfile: "Профиль пользователя",
  usersList: "Список пользователей",
  signUp: "Зарегистрироваться",
  lockScreen: "Экран блокировки",
  imageCropper: "Изображение Cropper",
  videoPlayer: "Видео проигрыватель",
  dropzone: "Зона сброса",
  reply: "Ответить",
  dashboardDashboard1: "Панель приборов / приборная панель 1",
  dashboardDashboard2: "Панель приборов / приборная панель 2",
  uiElementsButtons: "Элементы / кнопки UI",
  uiElementsCalendar: "Элементы / Календарь",
  uiElementsProgressBar: "Панель пользовательских интерфейсов / индикатор выполнения",
  uiElementsTabs: "UI-элементы / вкладки",
  uiElementsAccordions: "UI-элементы / аккордеоны",
  uiElementsPagination: "UI-элементы / разбиение на страницы",
  uiElementsTooltip: "UI-Elements / Tooltip",
  uiElementsBadges: "UI-элементы / значки",
  uiElementsCards: "UI-элементы / карты",
  uiElementsAlerts: "UI-элементы / предупреждения",
  uiElementsCarousel: "UI-элементы / карусель",
  editorQuillEditor: "Редактор / редактор Quill",
  editorWYSIWYG: "Редактор / WYSIWYG",
  dragAndDropVueDraggable: "Drag And Drop / Vue Draggable",
  dragAndDropVue2Dragula: "Drag And Drop / Vue2 Dragula",
  dragAndDropDraggableResizable: "Перетаскивание и перетаскивание",
  iconsThemify: "Иконки / Themify",
  iconsMaterial: "Иконы / Материал",
  iconsFontAwesome: "Иконки / Шрифт Awesome",
  chartsVueChartjs: "Графики / Vue Chartjs",
  chartsVueEcharts: "Графики / Vue Echarts",
  mapsGoogleMaps: "Карты / Карты Google",
  mapsLeafletMaps: "Карты / Карты буклетов",
  tablesTables: "Столы / Столы",
  tablesResponsiveTable: "Таблицы / отзывчивая таблица",
  pagesGallery: "Страницы / Галерея",
  pagesPricing: "Страницы / Цены",
  pagesBlank: "Страницы / Пусто",
  usersUserProfile: "Пользователи / Профиль пользователя",
  usersUsersList: "Пользователи / Список пользователей",
  featuresImageCropper: "Особенности / Image Cropper",
  featuresVideoPlayer: "Особенности / Видеопроигрыватель",
  featuresDropzone: "Особенности / Dropzone",
  crm:"CRM",
  reports:"Отчеты",
  dashboardCrm: "Приборная доска / CRM",
  dashboardReports: "Панель инструментов / Отчеты",
  invoices:"Счета-фактуры",
  payments:"платежи",
  taxRates:"Налоговые ставки",
  addTickets:"Добавить билеты",
  visitors:"Посетители",
  revenue:"доходов",
  deals:"предложения",
  trade:"Сделка",
  projectStatus:"Статус проекта",
  upcomingEvents:"Предстоящие События",
  ongoingProjects:"Текущие проекты",
  projectStatus:"Статус проекта",
  supervisor:"Руководитель",
  duration:"продолжительность",
  netWorth:"NetWorth",
  email:"Эл. адрес",
  phone:"Телефон",
  viewAllNotifications:"Просмотреть все уведомления",
  chat:"чат",
  transactionList:"Список транзакций",
  transferReport:"Отчет о передаче",
  expenseCategory:"Категория расходов",
  projects: "проектов",
  projectDetails: "Подробности проекта",
  reports:"Отчеты",
  clients: "клиенты",
  search: "Поиск",
  add: "добавлять",
  crmProjects: "CRM / Проекты",
  projectGrid: "Сетка проекта",
  projectList: "Список проектов",
  crmProjectDetails: "CRM / Детали проекта",
  filesUploaded: "Загруженные файлы",
  statistics: "Статистика",
  projectGallery: "Галерея проектов",
  progress: "Прогресс",
  allClients: "Все клиенты",
  favourite: "любимец",
  recentlyAdded: "Недавно добавленный",
  crmReports: "CRM / Отчеты",
  crmClients: "CRM / Клиенты",
  dashboardCrypto: "Панель инструментов / Крипто",
  crypto: "крипто-",
  marketCap: "Рыночная капитализация",
  wallet: "Бумажник",
  trade: "Сделка",
  tradeHistory: "История Торговли",
  safeTrade: "Безопасная торговля",
  exchangeStatistics: "Статистика обмена",
  quickTrade: "Быстрая торговля",
  recentTrades: "Недавние торги",
  exchangeRate: "Курс обмена",
  coinsList: "Список монет",
  cryptoMarketCap: "Крипто / Рыночная Кепка",
  cryptoWallet: "Крипто / Кошелек",
  cryptoTrade: "Крипто / Торговля",
  walletAddress: "Адрес кошелька",
  buyCryptocurrency: "Купить криптовалюту",
  expenditureStats: "Статистика расходов",
  sellCryptocurrency: "Продать криптовалюту",
  transferCryptocurrency: "Перевод криптовалюты",
  bankDetails: "Банковские реквизиты"
}
//messages.js
export default {
   sendEmail:"E-Mail senden",
   enterYourEmailToSendYouAResetLink:"Geben Sie Ihre E-Mail-Adresse ein, um Ihnen einen Reset-Link zu senden",
   salesReport: "Verkaufsbericht",
   totalRevenue: "Gesamtumsatz",
   revenueToday: "Einnahmen heute",
   totalOrders: "Gesamtbestellungen",
   newOrdersToday: "Neue Aufträge heute",
   userStats: "Benutzerstatistiken",
   salesByCategory: "Umsatz nach Kategorie",
   trending: "Trend",
   newArticle: "Neuer Artikel",
   today: "Heute",
   advertiseCampaign: "Kampagne werben",
   bestSeller: "Bestseller, Verkaufsschlager, Spitzenreiter",
   sales: "Der Umsatz",
   rating: "Bewertung",
   comments: "Bemerkungen",
   addNewBlogItem: "Neues Blogelement hinzufügen",
   addNew: "Neue hinzufügen",
   globalSalesByTopLocation: "Globale Verkäufe nach Top-Standort",
   salesTarget: "Umsatzziel",
   orders: "Aufträge",
   newVisitors: "Neue Besucher",
   subscriptions: "Abonnements",
   blogTitle: "Blog Titel",
   description: "Beschreibung",
   postBlog: "Blog veröffentlichen",
   scheduleLater: "Später planen",
   recentPosts: "kürzliche Posts",
   browserStats: "Browserstatistiken",
   send: "Senden",
   citiesAndClients: "Städte und Kunden",
   connections: "Verbindungen",
   couponStats: "Gutscheinstatistik",
   dailySales: "Tägliche Verkäufe",
   salesToday: "Verkauf heute",
   expenses: "Kosten",
   totalExpense: "Gesamtausgaben",
   inbox: "Posteingang",
   compose: "Komponieren",
   all: "Alle",
   unread: "Ungelesen",
   overallTrafficStatus: "Gesamtverkehrsstatus",
   whoToFollow: "Wem folgen",
   follow: "Folgen",
   following: "Folgend",
   photos: "Fotos",
   realTimeTraffic: "Echtzeitverkehr",
   recentComments: "Letzte Kommentare",
   recentOrderAndStatus: "Letzte Bestellung und Status",
   totalSales: "Gesamtumsatz",
   newCustomers: "neue Kunden",
   totalCustomers: "Gesamtkunden",
   skills: "Kompetenzen",
   likes: "Likes",
   followers: "Anhänger",
   statistic: "Statistik",
   sold: "Verkauft",
   inStock: "Auf Lager",
   amountOfStocksSold: "Anzahl der verkauften Aktien",
   viewMore: "Mehr sehen",
   systemReports: "Systemberichte",
   view: "Aussicht",
   edit: "Bearbeiten",
   delete: "Löschen",
   toDoList: "Aufgabenliste",
   topBuyers: "Top-Käufer",
   viewProfile: "Profil anzeigen",
   sendMessage: "Nachricht senden",
   articles: "Artikel",
   buyersStats: "Käuferstatistik",
   salesAndVisitorStatus: "Verkäufe und Besucherstatus",
   websiteStatistics: "Website-Statistiken",
   serverLoad: "Server laden",
   CPULoad: "CPU-Auslastung",
   storageRemain: "Lagerung bleibt erhalten",
   bandwidthUse: "Bandbreite verwenden",
   recentTestimonial: "Jüngstes Testimonial",
   productStats: "Produkt Statistiken",
   activityTimeline: "Aktivitätszeitachse",
   themeSettings: "Themen Einstellungen",
   chooseThemeColor: "Wählen Sie Thema Farbe",
   boxLayout: "Box-Layout",
   darkMode: "Dunkler Modus",
   quickLinks: "Schnelle Links",
   notifications: "Benachrichtigungen",
   system: "System",
   quickResults: "Schnelle Ergebnisse",
   account: "Konto",
   autoUpdates: "Automatische Updates",
   offlineMode: "Offline-Modus",
   locationShare: "Standort Teilen",
   showOfflineUser: "Offline-Benutzer anzeigen",
   saveHistory: "Geschichte speichern",
   todaySales: "Heute Verkauf",
   itemsSold: "Artikel verkauft",
   customerTarget: "Kundenziel",
   ofTheMonth: "des Monats",
   myProfile: "Mein Profil",
   calendar: "Kalender",
   myInbox: "Mein Posteingang",
   myTasks: "Meine Aufgaben",
   logOut: "Ausloggen",
   lineChart: "Liniendiagramm",
   barChart: "Balkendiagramm",
   doughnutChart: "Donut-Diagramm",
   pieChart: "Kuchendiagramm",
   radarChart: "Radar-Diagramm",
   polarAreaChart: "Polares Bereich Diagramm",
   bubbleChart: "Blasendiagramm",
   funnelChart: "Trichterdiagramm",
   vue2Dragula: "Vue 2 Dragula",
   vueDraggable: "Vue schleppbar",
   draggableResizable: "Draggable Resizable",
   quillEditor: "Federredakteur",
   wysiwyg: "WYSIWYG",
   cropImage: "Bild zuschneiden",
   previewImage: "Vorschaubild",
   baseConfig: "Basiskonfiguration",
   audioTrackAndPlaysInline: "Audio Track und spielt Inline",
   hlsLive: "HLS Live",
   listOfAllMaterialIcons: "Liste aller Materialikonen",
   arrowsAndDirectionIcons: "Pfeile und Richtungssymbole",
   webAppIcons: "Web-App-Symbole",
   controlIcons: "Steuerungssymbole",
   textEditorIcons: "Texteditor Icons",
   layoutIcons: "Layout-Symbole",
   brandIcons: "Marken-Icons",
   googleMap: "Google Karte",
   leafletMap: "Faltblatt-Karte",
   choosePlan: "Wählen Sie Planen",
   login: "Anmeldung",
   enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Geben Sie Benutzernamen und Passwort ein, um auf das Control Panel von Adminify zuzugreifen.",
   rememberMe: "Erinnere dich an mich",
   forgotPassword: "Passwort vergessen?",
   dontHaveAnAccount: "Hast du kein Konto?",
   orLoginWith: "Oder Einloggen mit",
   registerToAdmin: "Zum Admin anmelden",
   createAccount: "Benutzerkonto anlegen",
   alreadyHaveAnAccount: "Hast du schon ein Konto ?",
   orRegisterWith: "Oder registrieren mit",
   basicTables: "Grundlegende Tabellen",
   tableInverse: "Tabelle umgekehrt",
   tableHead: "Tischkopf",
   stripedRows: "Gestreifte Zeilen",
   borderedTable: "Begrenzte Tabelle",
   hoverableTable: "Erstellbare Tabelle",
   contextualTable: "Kontexttabelle",
   responsiveTable: "Responsive Tabelle",
   responsiveTable2: "Responsive Tabelle 2",
   collapse: "Zusammenbruch",
   toggleCollapse: "Collapse umschalten",
   toggleInnerCollapse: "Innerer Kollaps umschalten",
   multipleCollapse: "Mehrfacher Zusammenbruch",
   toggleBothCollapseAandB: "Toggle Collapse A und B",
   accordion: "Akkordeon",
   basicTab: "Grundlegende Registerkarte",
   closedItem: "Geschlossenes Element",
   closedItem2: "Geschlossener Gegenstand 2",
   linkColor: "Verknüpfungsfarbe",
   additionalcontentInsideAlerts: "Zusätzliche Inhalte innerhalb von Benachrichtigungen",
   normalAlertsWithDismiss: "Normale Warnungen mit Dismiss",
   autoDismissingAlerts: "Automatisches Beenden von Warnungen",
   showAlertWithCountdownTimer: "Alarm mit Countdown-Timer anzeigen",
   badgeSize: "Abzeichen Größe",
   badgesColor: "Abzeichen Farbe",
   predefinedButtonStyles: "Vordefinierte Schaltflächenstile",
   outlineButtons: "Gliederungsschaltflächen",
   buttonsSizes: "Schaltflächen Größen",
   blockLevelButtons: "Blockiere Ebenen-Buttons",
   blockLevelButton: "Schaltfläche Blockieren Ebene",
   dropDownAndDropUp: "DropDown & Dropup",
   carousel: "Karussell",
   firstSlide: "Erste Folie",
   helloWorld: "Hallo Welt",
   basicUsage: "Grundlegende Verwendung",
   alignment: "Ausrichtung",
   simpleProgressBarWithLables: "Einfache Fortschrittsleiste mit Etiketten",
   coloredProgressBars: "Farbige Fortschrittsbalken",
   stackedBars: "Gestapelte Bars",
   sizingProressBar: "Sizing Progress Bar",
   stripedProgressBar: "Gestreifte Fortschrittsleiste",
   pillsVariant: "Pillen-Variante",
   advancedExamples: "Erweiterte Beispiele",
   previous: "Bisherige",
   next: "Nächster",
   dynamicTabs: "Dynamische Registerkarten",
   imaToolTip: "Ich bin ein Tooltip!",
   hoverMe: "Schwebe mich",
   positioning: "Positionierung",
   tooltipOnTop: "Tooltip an der Spitze",
   tooltipOnBottom: "Tooltip auf der Unterseite",
   tooltipOnLeft: "Tooltipp auf der linken Seite",
   tooltipOnRight: "Tooltip auf rechts",
   popover: "Popover",
   popoverTitle: "Popover-Titel",
   popoverPositioning: "Popover-Positionierung",
   popoverOnTop: "Popover an der Spitze",
   popoverOnBottom: "Popover auf der Unterseite",
   popoverOnLeft: "Popover auf der linken Seite",
   popoverOnRight: "Popover auf rechts",
   by: "Durch",
   editPost: "Beitrag bearbeiten",
   deletePost: "Beitrag entfernen",
   standard: "Standard",
   forMostOfTheUsers: "Für die meisten Benutzer",
   mega: "Mega",
   master: "Meister",
   forDeveloper: "Für Entwickler",
   forLargeEnterprises: "Für große Unternehmen",
   dashboard: "Instrumententafel",
   dashboard1: "Dashboard 1",
   dashboard2: "Dashboard 2",
   uiElements: "UI-Elemente",
   alerts: "Benachrichtigungen",
   buttons: "Tasten",
   progressBar: "Fortschrittsanzeige",
   tabs: "Registerkarten",
   accordions: "Akkordeons",
   pagination: "Seitennummerierung",
   tooltip: "Kurzinfo",
   badges: "Abzeichen",
   cards: "Karten",
   editor: "Editor",
   dragAndDrop: "Ziehen und ablegen",
   icons: "Symbole",
   charts: "Diagramme",
   tables: "Tabellen",
   maps: "Karten",
   pages: "Seiten",
   users: "Benutzer",
   session: "Session",
   features: "Eigenschaften",
   themify: "Themify",
   material: "Material",
   fontAwesome: "Schrift Awesome",
   vueChartjs: "Vue Chartjs",
   vueEcharts: "Vue Echarts",
   basic: "Basic",
   responsive: "Reagierend",
   googleMaps: "Google Maps",
   leafletMaps: "Prospektkarten",
   gallery: "Galerie",
   pricing: "Preisgestaltung",
   blank: "Leer",
   userProfile: "Benutzerprofil",
   usersList: "Benutzerliste",
   signUp: "Anmelden",
   lockScreen: "Bildschirm sperren",
   imageCropper: "Bildkrämer",
   videoPlayer: "Videoplayer",
   dropzone: "Abwurfgebiet",
   reply: "Antworten",
   dashboardDashboard1: "Dashboard / Dashboard 1",
   dashboardDashboard2: "Dashboard / Dashboard 2",
   uiElementsButtons: "UI-Elemente / Schaltflächen",
   uiElementsCalendar: "UI-Elemente / Kalender",
   uiElementsProgressBar: "UI-Elemente / Fortschrittsbalken",
   uiElementsTabs: "UI-Elemente / Tabs",
   uiElementsAccordions: "UI-Elemente / Akkordeons",
   uiElementsPagination: "UI-Elemente / Seitenumbruch",
   uiElementsTooltip: "UI-Elemente / Tooltip",
   uiElementsBadges: "UI-Elemente / Badges",
   uiElementsCards: "UI-Elemente / Karten",
   uiElementsAlerts: "UI-Elemente / Warnungen",
   uiElementsCarousel: "UI-Elemente / Karussell",
   editorQuillEditor: "Redakteur / Redakteur",
   editorWYSIWYG: "Editor / WYSIWYG",
   dragAndDropVueDraggable: "Ziehen und Ablegen / Vue Draggable",
   dragAndDropVue2Dragula: "Ziehen und Fallenlassen / Vue2 Dragula",
   dragAndDropDraggableResizable: "Ziehen und Ablegen / Ziehen veränderbar",
   iconsThemify: "Icons / Themify",
   iconsMaterial: "Symbole / Material",
   iconsFontAwesome: "Symbole / Schrift Awesome",
   chartsVueChartjs: "Charts / Vue Charts",
   chartsVueEcharts: "Charts / Vue-Diagramme",
   mapsGoogleMaps: "Karten / Google Maps",
   mapsLeafletMaps: "Karten / Prospekte",
   tablesTables: "Tische / Tische",
   tablesResponsiveTable: "Tabellen / Responsive Tabelle",
   pagesGallery: "Seiten / Galerie",
   pagesPricing: "Seiten / Preise",
   pagesBlank: "Seiten / Leer",
   usersUserProfile: "Benutzer / Benutzerprofil",
   usersUsersList: "Benutzer / Benutzerliste",
   featuresImageCropper: "Funktionen / Bildkrämer",
   featuresVideoPlayer: "Eigenschaften / Video Player",
   featuresDropzone: "Funktionen / Dropzone",
   crm:"CRM",
   reports:"Berichte",
   dashboardCrm: "Dashboard / CRM",
   dashboardReports: "Dashboard / Berichte",
   invoices:"Rechnungen",
   payments:"Zahlungen",
   taxRates:"Steuersätze",
   addTickets:"Tickets hinzufügen",
   visitors:"Besucher",
   revenue:"Einnahmen",
   deals:"Angebote",
   trade:"Handel",
   projectStatus:"Projekt-Status",
   upcomingEvents:"Kommende Veranstaltungen",
   ongoingProjects:"Laufende Projekte",
   projectStatus:"Projekt-Status",
   supervisor:"Supervisor",
   duration:"Dauer",
   netWorth:"Netz wert",
   email:"Email",
   phone:"Telefon",
   viewAllNotifications:"Alle Benachrichtigungen anzeigen",
   chat:"Plaudern",
   transactionList:"Transaktionsliste",
   transferReport:"Übertragungsbericht",
   expenseCategory:"Ausgabenkategorie",
   projects: "Projekte",
   projectDetails: "Projekt Details",
   reports:"Berichte",
   clients: "Kunden",
   search: "Suche",
   add: "Hinzufügen",
   crmProjects: "CRM / Projekte",
   projectGrid: "Projektraster",
   projectList: "Projektliste",
   crmProjectDetails: "CRM / Projektdetails",
   filesUploaded: "Hochgeladene Dateien",
   statistics: "Statistiken",
   projectGallery: "Projekt Galerie",
   progress: "Fortschritt",
   allClients: "Alle Kunden",
   favourite: "Liebling",
   recentlyAdded: "Kürzlich hinzugefügt",
   crmReports: "CRM / Berichte",
   crmClients: "CRM / Kunden",
   dashboardCrypto: "Dashboard / Krypto",
   crypto: "Krypto",
   marketCap: "Marktkapitalisierung",
   wallet: "Brieftasche",
   trade: "Handel",
   tradeHistory: "Handelsgeschichte",
   safeTrade: "Sicherer Handel",
   exchangeStatistics: "Statistiken austauschen",
   quickTrade: "Schneller Handel",
   recentTrades: "Letzte Trades",
   exchangeRate: "Tauschrate",
   coinsList: "Münzen Liste",
   cryptoMarketCap: "Krypto / Marktkapitalisierung",
   cryptoWallet: "Krypto / Brieftasche",
   cryptoTrade: "BaKrypto / Handelnkdaten",
   walletAddress: "Brieftasche Adresse",
   buyCryptocurrency: "Crypto kaufen",
   expenditureStats: "Ausgabenstatistik",
   sellCryptocurrency: "Kryptowährung verkaufen",
   transferCryptocurrency: "Kryptowährung übertragen",
   bankDetails: "Bankdaten"
}
//messages.js
export default {
  sendEmail:"Send Email",
  enterYourEmailToSendYouAResetLink: "Enter your email to send you a Reset Link",
  salesReport: "Sales Report",
  totalRevenue: "Total Revenue",
  revenueToday: "Revenue Today",
  totalOrders: "Total Orders",
  newOrdersToday: "New Orders Today",
  userStats: "User Stats",
  salesByCategory: "Sales By Category",
  trending: "Trending",
  newArticle: "New Article",
  today: "Today",
  advertiseCampaign: "Advertise Campaign",
  bestSeller: "Best Seller",
  sales: "Sales",
  rating: "Rating",
  comments: "Comments",
  addNewBlogItem: "Add New Blog Item",
  addNew: "Add New",
  globalSalesByTopLocation: "Global Sales By Top Location",
  salesTarget: "Sales Target",
  orders: "Orders",
  newVisitors: "New Visitors",
  subscriptions: "Subscriptions",
  blogTitle: "Blog Title",
  description: "Description",
  postBlog: "Post Blog",
  scheduleLater: "Schedule Later",
  recentPosts: "Recent Posts",
  browserStats: "Browser Stats",
  send: "Send",
  citiesAndClients: "Cities and Clients",
  connections: "Connections",
  couponStats: "Coupon Stats",
  dailySales: "Daily Sales",
  salesToday: "Sales Today",
  expenses: "Expenses",
  totalExpense: "Total Expense",
  inbox: "Inbox",
  compose: "Compose",
  all: "All",
  unread: "Unread",
  overallTrafficStatus: "Overall Traffic Status",
  whoToFollow: "Who to follow",
  follow: "Follow",
  following: "Following",
  photos: "Photos",
  realTimeTraffic: "Real Time Traffic",
  recentComments: "Recent Comments",
  recentOrderAndStatus: "Recent Order & Status",
  totalSales: "Total Sales",
  newCustomers: "New Customers",
  totalCustomers: "Total Customers",
  skills: "Skills",
  likes: "Likes",
  followers: "Followers",
  statistic: "Statistic",
  sold: "Sold",
  inStock: "In-Stock",
  amountOfStocksSold: "Amount Of Stocks Sold",
  viewMore: "View More",
  systemReports: "System Reports",
  view: "View",
  edit: "Edit",
  delete: "Delete",
  toDoList: "To-Do List",
  topBuyers: "Top Buyers",
  viewProfile: "View Profile",
  sendMessage: "Send Message",
  articles: "Articles",
  buyersStats: "Buyers Stats",
  salesAndVisitorStatus: "Sales And Visitor Status",
  websiteStatistics: "Website Statistics",
  serverLoad: "Server Load",
  CPULoad: "CPU Load",
  storageRemain: "Storage Remain",
  bandwidthUse: "Bandwidth Use",
  recentTestimonial: "Recent Testimonial",
  productStats: "Product Stats",
  activityTimeline: "Activity Timeline",
  themeSettings: "Theme Settings",
  chooseThemeColor: "Choose Theme Color",
  boxLayout: "Box Layout",
  darkMode: "Dark Mode",
  quickLinks: "Quick Links",
  notifications: "Notifications",
  system: "System",
  quickResults: "Quick Results",
  account: "Account",
  autoUpdates: "Auto Updates",
  offlineMode: "Offline Mode",
  locationShare: "Location Share",
  showOfflineUser: "Show Offline User",
  saveHistory: "Save History",
  todaySales: "Today Sales",
  itemsSold: "items sold",
  customerTarget: "Customer Target",
  ofTheMonth: "of the month",
  myProfile: "My Profile",
  calendar: "Calendar",
  myInbox: "My Inbox",
  myTasks: "My Tasks",
  logOut: "LogOut",
  lineChart: "Line Chart",
  barChart: "Bar Chart",
  doughnutChart: "Doughnut Chart",
  pieChart: "Pie Chart",
  radarChart: "Radar Chart",
  polarAreaChart: "Polar Area Chart",
  bubbleChart: "Bubble Chart",
  funnelChart: "Funnel Chart",
  vue2Dragula: "Vue 2 Dragula",
  vueDraggable: "Vue Draggable",
  draggableResizable: "Draggable Resizable",
  quillEditor: "Quill Editor",
  wysiwyg: "WYSIWYG",
  cropImage: "Crop Image",
  previewImage: "Preview Image",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Audio Track And Plays Inline",
  hlsLive: "HLS Live",
  listOfAllMaterialIcons: "List Of All Material Icons",
  arrowsAndDirectionIcons: "Arrows & Direction Icons",
  webAppIcons: "Web App Icons",
  controlIcons: "Control Icons",
  textEditorIcons: "Text Editor Icons",
  layoutIcons: "Layout Icons",
  brandIcons: "Brand Icons",
  googleMap: "Google Map",
  leafletMap: "Leaflet Map",
  choosePlan: "Choose Plan",
  login: "Login",
  enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Enter username and password to access control panel of Adminify.",
  rememberMe: "Remember Me",
  forgotPassword: "Forgot Password?",
  dontHaveAnAccount: "Don't have an account ?",
  orLoginWith: "Or Login With",
  registerToAdmin: "Register To Admin",
  createAccount: "Create Account",
  alreadyHaveAnAccount: "Already have an account ?",
  orRegisterWith: "Or Register With",
  basicTables: "Basic Tables",
  tableInverse: "Table Inverse",
  tableHead: "Table Head",
  stripedRows: "Striped Rows",
  borderedTable: "Bordered Table",
  hoverableTable: "Hoverable Table",
  contextualTable: "Contextual Table",
  responsiveTable: "Responsive Table",
  responsiveTable2: "Responsive Table 2",
  collapse: "Collapse",
  toggleCollapse: "Toggle Collapse",
  toggleInnerCollapse: "Toggle Inner Collapse",
  multipleCollapse: "Multiple Collapse",
  toggleBothCollapseAandB: "Toggle Both Collapse A and B",
  accordion: "Accordion",
  basicTab: "Basic Tab",
  closedItem: "Closed Item",
  closedItem2: "Closed Item 2",
  linkColor: "Link Color",
  additionalcontentInsideAlerts: "Additional Content Inside Alerts",
  normalAlertsWithDismiss: "Normal Alerts With Dismiss",
  autoDismissingAlerts: "Auto Dismissing Alerts",
  showAlertWithCountdownTimer: "Show alert with count-down timer",
  badgeSize: "Badge Size",
  badgesColor: "Badges Color",
  predefinedButtonStyles: "Predefined Button Styles",
  outlineButtons: "Outline Buttons",
  buttonsSizes: "Buttons Sizes",
  blockLevelButtons: "Block Level Buttons",
  blockLevelButton: "Block Level Button",
  dropDownAndDropUp: "DropDown & Dropup",
  carousel: "Carousel",
  firstSlide: "First Slide",
  helloWorld: "Hello World",
  basicUsage: "Basic Usage",
  alignment: "Alignment",
  simpleProgressBarWithLables: "Simple Progress Bar With Labels",
  coloredProgressBars: "Colored Progress Bars",
  stackedBars: "Stacked Bars",
  sizingProressBar: "Sizing Progress Bar",
  stripedProgressBar: "Striped Progress Bar",
  pillsVariant: "Pills Variant",
  advancedExamples: "Advanced Examples",
  previous: "Previous",
  next: "Next",
  dynamicTabs: "Dynamic Tabs",
  imaToolTip: "I'm a tooltip!",
  hoverMe: "Hover Me",
  positioning: "Positioning",
  tooltipOnTop: "Tooltip On Top",
  tooltipOnBottom: "Tooltip On Bottom",
  tooltipOnLeft: "Tooltip On Left",
  tooltipOnRight: "Tooltip On Right",
  popover: "Popover",
  popoverTitle: "Popover Title",
  popoverPositioning: "Popover Positioning",
  popoverOnTop: "Popover On Top",
  popoverOnBottom: "Popover On Bottom",
  popoverOnLeft: "Popover On Left",
  popoverOnRight: "Popover On Right",
  by: "By",
  editPost: "Edit Post",
  deletePost: "Delete Post",
  standard: "Standard",
  forMostOfTheUsers: "For Most Of the Users",
  mega: "Mega",
  master: "Master",
  forDeveloper: "For Developer",
  forLargeEnterprises: "For Large enterprises",
  dashboard: "Dashboard",
  dashboard1: "Dashboard 1",
  dashboard2: "Dashboard 2",
  uiElements: "UI Elements",
  alerts: "Alerts",
  buttons: "Buttons",
  progressBar: "Progress Bar",
  tabs: "Tabs",
  accordions: "Accordions",
  pagination: "Pagination",
  tooltip: "Tooltip",
  badges: "Badges",
  cards: "Cards",
  editor: "Editor",
  dragAndDrop: "Drag And Drop",
  icons: "Icons",
  charts: "Charts",
  tables: "Tables",
  maps: "Maps",
  pages: "Pages",
  users: "Users",
  session: "Session",
  features: "Features",
  themify: "Themify",
  material: "Material",
  fontAwesome: "Font Awesome",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  basic: "Basic",
  responsive: "Responsive",
  googleMaps: "Google Maps",
  leafletMaps: "Leaflet Maps",
  gallery: "Gallery",
  pricing: "Pricing",
  blank: "Blank",
  userProfile: "User Profile",
  usersList: "Users List",
  signUp: "SignUp",
  lockScreen: "Lock Screen",
  imageCropper: "Image Cropper",
  videoPlayer: "Video Player",
  dropzone: "Dropzone",
  reply: "Reply",
  dashboardDashboard1: "Dashboard / Dashboard 1",
  dashboardDashboard2: "Dashboard / Dashboard 2",
  dashboardCrm: "Dashboard / CRM",
  uiElementsButtons: "UI-Elements / Buttons",
  uiElementsCalendar: "UI-Elements / Calendar",
  uiElementsProgressBar: "UI-Elements / Progress Bar",
  uiElementsTabs: "UI-Elements / Tabs",
  uiElementsAccordions: "UI-Elements / Accordions",
  uiElementsPagination: "UI-Elements / Pagination",
  uiElementsTooltip: "UI-Elements / Tooltip",
  uiElementsBadges: "UI-Elements / Badges",
  uiElementsCards: "UI-Elements / Cards",
  uiElementsAlerts: "UI-Elements / Alerts",
  uiElementsCarousel: "UI-Elements / Carousel",
  editorQuillEditor: "Editor / Quill Editor",
  editorWYSIWYG: "Editor / WYSIWYG",
  dragAndDropVueDraggable: "Drag And Drop / Vue Draggable",
  dragAndDropVue2Dragula: "Drag And Drop / Vue2 Dragula",
  dragAndDropDraggableResizable: "Drag And Drop / Draggable Resizable",
  iconsThemify: "Icons / Themify",
  iconsMaterial: "Icons / Material",
  iconsFontAwesome: "Icons / Font Awesome",
  chartsVueChartjs: "Charts / Vue Chartjs",
  chartsVueEcharts: "Charts / Vue Echarts",
  mapsGoogleMaps: "Maps / Google Maps",
  mapsLeafletMaps: "Maps / Leaflet Maps",
  tablesTables: "Tables / Tables",
  tablesResponsiveTable: "Tables / Responsive Table",
  pagesGallery: "Pages / Gallery",
  pagesPricing: "Pages / Pricing",
  pagesBlank: "Pages / Blank",
  usersUserProfile: "Users / User Profile",
  usersUsersList: "Users / Users List",
  featuresImageCropper: "Features / Image Cropper",
  featuresVideoPlayer: "Features / Video Player",
  featuresDropzone: "Features / Dropzone",
  crm:"CRM",
  reports:"Reports",
  dashboardCRM: "Dashboard / CRM",
  dashboardReports: "Dashboard / Reports",
  invoices:"Invoices",
  payments:"Payments",
  taxRates:"Tax Rates",
  addTickets:"Add Tickets",
  visitors:"Visitors",
  revenue:"Revenue",
  deals:"Deals",
  trade:"Trade",
  projectStatus:"Project Status",
  upcomingEvents:"Upcoming Events",
  ongoingProjects:"Ongoing Projects",
  supervisor:"Supervisor",
  duration:"Duration",
  netWorth:"NetWorth",
  email:"Email",
  phone:"Phone",
  viewAllNotifications:"View All Notifications",
  chat:"Chat",
  transactionList:"Transaction List",
  transferReport:"Transfer Report",
  expenseCategory:"Expense Category",
  projects: "Projects",
  projectDetails: "Project Details",
  clients: "Clients",
  search: "Search",
  add: "Add",
  crmProjects: "CRM / Projects",
  projectGrid: "Project Grid",
  projectList: "Project List",
  crmProjectDetails: "CRM / Project Details",
  filesUploaded: "Files Uploaded",
  statistics: "Statistics",
  projectGallery: "Project Gallery",
  progress: "Progress",
  allClients: "All Clients",
  favourite: "Favourite",
  recentlyAdded: "Recently Added",
  crmReports: "CRM / Reports",
  crmClients: "CRM / Clients",
  dashboardCrypto: "Dashboard / Crypto",
  crypto: "Crypto",
  marketCap: "Market Cap",
  wallet: "Wallet",
  tradeHistory: "Trade History",
  safeTrade: "Safe Trade",
  exchangeStatistics: "Exchange Statistics",
  quickTrade: "Quick Trade",
  recentTrades: "Recent Trades",
  exchangeRate: "Exchange Rate",
  coinsList: "Coins List",
  cryptoMarketCap: "Crypto / Market Cap",
  cryptoWallet: "Crypto / Wallet",
  bankDetails: "Bank Details",
  cryptoTrade: "Crypto / Trade",
  walletAddress: "Wallet Address",
  buyCryptocurrency: "Buy Cryptocurrency",
  expenditureStats: "Expenditure Stats",
  sellCryptocurrency: "Sell Cryptocurrency",
  transferCryptocurrency: "Transfer Cryptocurrency"
}
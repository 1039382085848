//messages.js
export default {
   sendEmail:"이메일을 보내",
   enterYourEmailToSendYouAResetLink:"나에게 링크를 보내려면 이메일을 입력하십시오.",
   salesReport: "판매 보고서",
   totalRevenue: "총 수익",
   revenueToday: "수익 오늘",
   totalOrders: "총 주문",
   newOrdersToday: "새로운 주문 오늘",
   userStats: "사용자 통계",
   salesByCategory: "카테고리 별 판매",
   trending: "인기 급상승",
   newArticle: "새로운 기사",
   today: "오늘",
   advertiseCampaign: "광고 캠페인",
   bestSeller: "베스트셀러",
   sales: "매상",
   rating: "평가",
   comments: "코멘트",
   addNewBlogItem: "새 블로그 항목 추가",
   addNew: "새로운 걸 더하다",
   globalSalesByTopLocation: "상위 위치 별 글로벌 판매",
   salesTarget: "판매 대상",
   orders: "명령",
   newVisitors: "새로운 방문자",
   subscriptions: "구독",
   blogTitle: "블로그 제목",
   description: "기술",
   postBlog: "포스트 블로그",
   scheduleLater: "나중에 예약",
   recentPosts: "최근 게시물",
   browserStats: "브라우저 통계",
   send: "보내다",
   citiesAndClients: "도시와 클라이언트",
   connections: "사이",
   couponStats: "쿠폰 통계",
   dailySales: "일일 판매",
   salesToday: "판매 오늘",
   expenses: "경비",
   totalExpense: "총 경비",
   inbox: "받은 편지함",
   compose: "짓다",
   all: "모든",
   unread: "읽히지 않는",
   overallTrafficStatus: "전체 트래픽 상태",
   whoToFollow: "따라야 할 사람",
   follow: "따르다",
   following: "수행원",
   photos: "사진들",
   realTimeTraffic: "실시간 교통",
   recentComments: "최근 댓글",
   recentOrderAndStatus: "최근 주문 및 상태",
   totalSales: "총 매출",
   newCustomers: "신규 고객",
   totalCustomers: "총 고객",
   skills: "기술",
   likes: "좋아요",
   followers: "추종자",
   statistic: "통계량",
   sold: "판매 된",
   inStock: "재고",
   amountOfStocksSold: "판매 된 주식의 금액",
   viewMore: "더보기",
   systemReports: "시스템 보고서",
   view: "전망",
   edit: "편집하다",
   delete: "지우다",
   toDoList: "할 일 목록",
   topBuyers: "인기 바이어",
   viewProfile: "프로필보기",
   sendMessage: "문자 보내",
   articles: "조항",
   buyersStats: "바이어 통계",
   salesAndVisitorStatus: "영업 및 방문자 상태",
   websiteStatistics: "웹 사이트 통계",
   serverLoad: "서버로드",
   CPULoad: "CPU로드",
   storageRemain: "저장 용량 유지",
   bandwidthUse: "대역폭 사용",
   recentTestimonial: "최근 회원 평가",
   productStats: "제품 통계",
   activityTimeline: "활동 일정",
   themeSettings: "주제 설정",
   chooseThemeColor: "테마 색상 선택",
   boxLayout: "상자 레이아웃",
   darkMode: "다크 모드",
   quickLinks: "빠른 링크",
   notifications: "알림",
   system: "체계",
   quickResults: "빠른 결과",
   account: "계정",
   autoUpdates: "자동 업데이트",
   offlineMode: "오프라인 모드",
   locationShare: "위치 공유",
   showOfflineUser: "오프라인 사용자 표시",
   saveHistory: "기록 저장",
   todaySales: "오늘 판매",
   itemsSold: "판매 된 품목",
   customerTarget: "고객 타겟",
   ofTheMonth: "그 달의",
   myProfile: "내 프로필",
   calendar: "달력",
   myInbox: "내받은 편지함",
   myTasks: "나의 과제",
   logOut: "로그 아웃",
   lineChart: "선형 차트",
   barChart: "막대 차트",
   doughnutChart: "도넛 차트",
   pieChart: "파이 차트",
   radarChart: "레이더 차트",
   polarAreaChart: "극지방 차트",
   bubbleChart: "버블 차트",
   funnelChart: "깔때기 형 차트",
   vue2Dragula: "Vue 2 Dragula",
   vueDraggable: "Vue Draggable",
   draggableResizable: "드래그 가능한 크기 조정 가능",
   quillEditor: "퀼 에디터",
   wysiwyg: "WYSIWYG",
   cropImage: "이미지 자르기",
   previewImage: "이미지 미리보기",
   baseConfig: "기본 구성",
   audioTrackAndPlaysInline: "오디오 트랙 및 인라인 재생",
   hlsLive: "HLS 라이브",
   listOfAllMaterialIcons: "모든 재료 아이콘 목록",
   arrowsAndDirectionIcons: "화살표 및 방향 아이콘",
   webAppIcons: "웹 응용 프로그램 아이콘",
   controlIcons: "제어 아이콘",
   textEditorIcons: "텍스트 편집기 아이콘",
   layoutIcons: "레이아웃 아이콘",
   brandIcons: "브랜드 아이콘",
   googleMap: "구글지도",
   leafletMap: "전단지지도",
   choosePlan: "플랜 선택",
   login: "로그인",
   enterUsernameAndPasswordToAccessControlPanelOfAdminify: "Adminify의 제어판에 액세스하려면 사용자 이름과 비밀번호를 입력하십시오.",
   rememberMe: "날 기억해",
   forgotPassword: "비밀번호를 잊으 셨나요?",
   dontHaveAnAccount: "계정이 없으십니까?",
   orLoginWith: "또는 다음으로 로그인",
   registerToAdmin: "관리자에게 등록",
   createAccount: "계정 만들기",
   alreadyHaveAnAccount: "이미 계정이 있으십니까?",
   orRegisterWith: "또는으로 등록하십시오",
   basicTables: "기본 테이블",
   tableInverse: "표 역수",
   tableHead: "테이블 헤드",
   stripedRows: "스트라이프 행",
   borderedTable: "테두리가있는 표",
   hoverableTable: "가져올 수있는 테이블",
   contextualTable: "컨텍스트 테이블",
   responsiveTable: "반응 형 테이블",
   responsiveTable2: "반응 형 표 2",
   collapse: "무너짐",
   toggleCollapse: "접기 전환",
   toggleInnerCollapse: "내부 붕괴 토글",
   multipleCollapse: "다중 접기",
   toggleBothCollapseAandB: "A와 B 모두 접기 전환",
   accordion: "아코디언",
   basicTab: "기본 탭",
   closedItem: "휴무일",
   closedItem2: "마감 품목 2",
   linkColor: "링크 색상",
   additionalcontentInsideAlerts: "알리미 내 추가 콘텐츠",
   normalAlertsWithDismiss: "일반적인 경고는 닫음",
   autoDismissingAlerts: "경고 자동 해제",
   showAlertWithCountdownTimer: "카운트 다운 타이머로 경고 표시",
   badgeSize: "배지 크기",
   badgesColor: "배지 색상",
   predefinedButtonStyles: "미리 정의 된 단추 스타일",
   outlineButtons: "개요 버튼",
   buttonsSizes: "단추 크기",
   blockLevelButtons: "블록 레벨 버튼",
   blockLevelButton: "블록 레벨 버튼",
   dropDownAndDropUp: "드롭 다운 및 드롭 다운",
   carousel: "회전 목마",
   firstSlide: "첫 번째 슬라이드",
   helloWorld: "안녕하세요 세계",
   basicUsage: "기본 사용법",
   alignment: "조정",
   simpleProgressBarWithLables: "레이블이있는 간단한 진행 표시 줄",
   coloredProgressBars: "컬러 진행 바",
   stackedBars: "누적 막대",
   sizingProressBar: "크기 조정 진행률 표시 줄",
   stripedProgressBar: "스트라이프 진행 표시 줄",
   pillsVariant: "환약",
   advancedExamples: "고급 예제",
   previous: "너무 이른",
   next: "다음 것",
   dynamicTabs: "동적 탭",
   imaToolTip: "나는 도구 설명이야!",
   hoverMe: "날 가져 가라.",
   positioning: "포지셔닝",
   tooltipOnTop: "툴팁 위에",
   tooltipOnBottom: "툴팁 하단",
   tooltipOnLeft: "좌측 툴팁",
   tooltipOnRight: "오른쪽 툴팁",
   popover: "Popover",
   popoverTitle: "Popover Title",
   popoverPositioning: "Popover 포지셔닝",
   popoverOnTop: "위로 Popover",
   popoverOnBottom: "Popover On Bottom",
   popoverOnLeft: "왼쪽 Popover",
   popoverOnRight: "오른쪽 Popover",
   by: "으로",
   editPost: "소식 수정",
   deletePost: "게시물 삭제",
   standard: "표준",
   forMostOfTheUsers: "대부분의 사용자에게",
   mega: "메가",
   master: "석사",
   forDeveloper: "개발자 용",
   forLargeEnterprises: "대기업의 경우",
   dashboard: "계기반",
   dashboard1: "대시 보드 1",
   dashboard2: "대시 보드 2",
   uiElements: "UI 요소",
   alerts: "알리미",
   buttons: "버튼",
   progressBar: "진행 표시 줄",
   tabs: "탭",
   accordions: "아코디언",
   pagination: "쪽수 매기기",
   tooltip: "툴팁",
   badges: "배지",
   cards: "카드",
   editor: "편집자",
   dragAndDrop: "끌어서 놓기",
   icons: "아이콘",
   charts: "차트",
   tables: "테이블",
   maps: "지도",
   pages: "페이지",
   users: "사용자",
   session: "세션",
   features: "풍모",
   themify: "Themify",
   material: "자료",
   fontAwesome: "글꼴 굉장",
   vueChartjs: "Vue Chartjs",
   vueEcharts: "Vue Echarts",
   basic: "기본",
   responsive: "반응 형",
   googleMaps: "구글지도",
   leafletMaps: "전단지지도",
   gallery: "갱도",
   pricing: "가격",
   blank: "공백",
   userProfile: "유저 프로필",
   usersList: "사용자 목록",
   signUp: "가입하기",
   lockScreen: "잠금 화면",
   imageCropper: "이미지 자르기 도구",
   videoPlayer: "비디오 플레이어",
   dropzone: "드롭 존",
   reply: "댓글",
   dashboardDashboard1: "대시 보드 / 대시 보드 1",
   dashboardDashboard2: "대시 보드 / 대시 보드 2",
   uiElementsButtons: "UI 요소 / 버튼",
   uiElementsCalendar: "UI 요소 / 달력",
   uiElementsProgressBar: "UI 요소 / 진행률 표시 줄",
   uiElementsTabs: "UI 요소 / 탭",
   uiElementsAccordions: "UI 요소 / 아코디언",
   uiElementsPagination: "UI 요소 / 페이지 매기기",
   uiElementsTooltip: "UI 요소 / 도구 설명",
   uiElementsBadges: "UI 요소 / 배지",
   uiElementsCards: "UI 요소 / 카드",
   uiElementsAlerts: "UI 요소 / 경고",
   uiElementsCarousel: "UI 요소 / 회전식 메뉴",
   editorQuillEditor: "에디터 / 퀼 에디터",
   editorWYSIWYG: "편집자 / WYSIWYG",
   dragAndDropVueDraggable: "드래그 앤 드롭 / 뷰 드래그 가능",
   dragAndDropVue2Dragula: "끌어서 놓기 / Vue2 Dragula",
   dragAndDropDraggableResizable: "드래그 앤 드롭 / 드래그 가능한 크기 조정 가능",
   iconsThemify: "아이콘 / Themify",
   iconsMaterial: "아이콘 / 재료",
   iconsFontAwesome: "아이콘 / 글꼴 굉장",
   chartsVueChartjs: "차트 / Vue Chartjs",
   chartsVueEcharts: "차트 / Vue Echarts",
   mapsGoogleMaps: "지도 / Google지도",
   mapsLeafletMaps: "지도 / 전단지지도",
   tablesTables: "테이블 / 테이블",
   tablesResponsiveTable: "테이블 / 반응 형 테이블",
   pagesGallery: "페이지 / 갤러리",
   pagesPricing: "페이지 / 가격",
   pagesBlank: "페이지 / 공백",
   usersUserProfile: "사용자 / 사용자 프로필",
   usersUsersList: "사용자 / 사용자 목록",
   featuresImageCropper: "특징 / 이미지 Cropper",
   featuresVideoPlayer: "기능 / 비디오 플레이어",
   featuresDropzone: "특징 / Dropzone",
   crm:"CRM",
   reports:"보고서",
   dashboardCrm: "계기반 / CRM",
   dashboardReports: "계기반 / 보고서",
   invoices:"인보이스",
   payments:"지불",
   taxRates:"세율",
   addTickets:"티켓 추가",
   visitors:"방문객",
   revenue:"수익",
   deals:"거래",
   trade:"무역",
   projectStatus:"프로젝트 상태",
   upcomingEvents:"다가오는 이벤트",
   ongoingProjects:"진행중인 프로젝트",
   projectStatus:"프로젝트 상태",
   supervisor:"감독자",
   duration:"지속",
   netWorth:"넷 워스",
   email:"이메일",
   phone:"전화",
   viewAllNotifications:"모든 알림보기",
   chat:"잡담",
   transactionList:"거래 목록",
   transferReport:"보고서 전송",
   expenseCategory:"비용 범주",
   projects: "프로젝트",
   projectDetails: "프로젝트 세부 정보",
   reports:"보고서",
   clients: "고객",
   search: "수색",
   add: "더하다",
   crmProjects: "CRM /プロジェクト",
   projectGrid: "프로젝트 그리드",
   projectList: "프로젝트 목록",
   crmProjectDetails: "CRM /プロジェクトの詳細",
   filesUploaded: "업로드 된 파일",
   statistics: "통계",
   projectGallery: "프로젝트 갤러리",
   progress: "진행",
   allClients: "모든 클라이언트",
   favourite: "인기 있는 말",
   recentlyAdded: "최근에 추가",
   crmReports: "CRM /レポート",
   crmClients: "CRM /クライアント",
   dashboardCrypto: "ダッシュボード/暗号",
   crypto: "암호",
   marketCap: "시가 총액",
   wallet: "지갑",
   trade: "무역",
   tradeHistory: "무역 역사",
   safeTrade: "안전 무역",
   exchangeStatistics: "교환 통계",
   quickTrade: "빠른 무역",
   recentTrades: "최근 거래",
   exchangeRate: "환율",
   coinsList: "동전 목록",
   cryptoMarketCap: "暗号/時価総額",
   cryptoWallet: "暗号/ウォレット",
   cryptoTrade: "暗号/貿易",
   walletAddress: "지갑 주소",
   buyCryptocurrency: "암호화 통화 구매",
   expenditureStats: "지출 통계",
   sellCryptocurrency: "암호화 통화 판매",
   transferCryptocurrency: "전송 암호화 통화",
   bankDetails: "은행 계좌 정보"
}